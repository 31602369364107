/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMetamodelEntity from 'Models/Security/Acl/VisitorsMetamodelEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getIdCrudOptions } from "Util/EntityUtils";
// % protected region % [Add any further imports here] end

export interface IMetamodelEntityAttributes extends IModelAttributes {
	isPublic: boolean;
	name: string;
	path: string;

	metamodelVersions: Array<
		| Models.MetamodelVersionEntity
		| Models.IMetamodelVersionEntityAttributes
	>;
	diagramId: string;
	diagram: Models.DiagramEntity | Models.IDiagramEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	previewDiagramId: string;
	previewDiagram: Models.DiagramEntity | Models.IDiagramEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MetamodelEntity', 'Metamodel')
// % protected region % [Customise your entity metadata here] end
export default class MetamodelEntity extends Model
	implements IMetamodelEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMetamodelEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Public'] off begin
	@observable
	@attribute<MetamodelEntity, boolean>()
	@CRUD({
		name: 'Is Public',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublic: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Public'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<MetamodelEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Path'] off begin
	@Validators.Required()
	@observable
	@attribute<MetamodelEntity, string>()
	@CRUD({
		name: 'Path',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public path: string;
	// % protected region % [Modify props to the crud options here for attribute 'Path'] end

	/**
	 * [CASCADE] Versions of a metamodel
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Versions',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.MetamodelVersionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'metamodelVersions',
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersions: Models.MetamodelVersionEntity[] = [];

	/**
	 * [CASCADE] Combined Structural and Mapping Diagram for a Metamodel
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram'] off begin
		name: 'Diagram',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.DiagramEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagrams',
			oppositeEntity: () => Models.DiagramEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram'] end
	})
	public diagramId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public diagram: Models.DiagramEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] on begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 40,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * [CASCADE] Diagram to preview modelling with the metamodel
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Preview Diagram'] off begin
		name: 'Preview Diagram',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.DiagramEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'previewDiagrams',
			oppositeEntity: () => Models.DiagramEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Preview Diagram'] end
	})
	public previewDiagramId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public previewDiagram: Models.DiagramEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMetamodelEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMetamodelEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isPublic !== undefined) {
				this.isPublic = attributes.isPublic;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.path !== undefined) {
				this.path = attributes.path;
			}
			if (attributes.metamodelVersions !== undefined && Array.isArray(attributes.metamodelVersions)) {
				for (const model of attributes.metamodelVersions) {
					if (model instanceof Models.MetamodelVersionEntity) {
						this.metamodelVersions.push(model);
					} else {
						this.metamodelVersions.push(new Models.MetamodelVersionEntity(model));
					}
				}
			}
			if (attributes.diagramId !== undefined) {
				this.diagramId = attributes.diagramId;
			}
			if (attributes.diagram !== undefined) {
				if (attributes.diagram === null) {
					this.diagram = attributes.diagram;
				} else if (attributes.diagram instanceof Models.DiagramEntity) {
					this.diagram = attributes.diagram;
					this.diagramId = attributes.diagram.id;
				} else {
					this.diagram = new Models.DiagramEntity(attributes.diagram);
					this.diagramId = this.diagram.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.previewDiagramId !== undefined) {
				this.previewDiagramId = attributes.previewDiagramId;
			}
			if (attributes.previewDiagram !== undefined) {
				if (attributes.previewDiagram === null) {
					this.previewDiagram = attributes.previewDiagram;
				} else if (attributes.previewDiagram instanceof Models.DiagramEntity) {
					this.previewDiagram = attributes.previewDiagram;
					this.previewDiagramId = attributes.previewDiagram.id;
				} else {
					this.previewDiagram = new Models.DiagramEntity(attributes.previewDiagram);
					this.previewDiagramId = this.previewDiagram.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		metamodelVersions {
			${Models.MetamodelVersionEntity.getAttributes().join('\n')}
		}
		diagram {
			${Models.DiagramEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		previewDiagram {
			${Models.DiagramEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			metamodelVersions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'diagram',
							'previewDiagram',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
const idAttr = getIdCrudOptions();
CRUD(createdAttr)(MetamodelEntity.prototype, 'created');
CRUD(modifiedAttr)(MetamodelEntity.prototype, 'modified');
CRUD(idAttr)(MetamodelEntity.prototype, 'id');
// % protected region % [Modify the create and modified CRUD attributes here] end
