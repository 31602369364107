/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { Model, IModelType } from 'Models/Model';
import { getFetchAllQuery, getModelName } from 'Util/EntityUtils';
import { lowerCaseFirst } from 'Util/StringUtils';
import { observer } from 'mobx-react';
import {
	action,
	computed,
	observable,
	runInAction,
} from 'mobx';
import { MultiCombobox } from '../../Combobox/MultiCombobox';
import { store } from 'Models/Store';
import _ from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { IAttributeProps } from './IAttributeProps';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { crudId } from 'Symbols';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type dropdownData = Array<{ display: string, value: any }>;
type state = 'loading' | 'error' | 'success';

export interface IAttributeReferenceComboboxProps<T extends Model> extends IAttributeProps<T> {
	/** A function that returns the type of model that is on the combobox */
	referenceType: IModelType;
	/** A function to override loading of the data into the dropdown */
	referenceResolveFunction?: (search: string | string[], options: { model: T }) => Promise<dropdownData>;
	/** A function to compare an option value with a value */
	optionEqualFunc?: (modelProperty: Model, option: string) => boolean;
	/** Is the entity in this combobox a join entity */
	isJoinEntity?: boolean;
	/** Can default options be removed from the combobox */
	disableDefaultOptionRemoval?: boolean;
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

/**
 * A dropdown menu that populates itself with references to other objects
 */
@observer
export default class AttributeReferenceMultiCombobox<T extends Model>
	extends React.Component<IAttributeReferenceComboboxProps<T>> {
	static defaultProps: Partial<IAttributeReferenceComboboxProps<Model>> = {
		optionEqualFunc: (modelProperty, option) => modelProperty.id === option,
	};

	@observable
	public requestState: { state: state, data?: dropdownData } = { state: 'loading' };

	@observable
	public options: T[] = [];

	@computed
	private get modelName() {
		const { referenceType } = this.props;
		const modelName = getModelName(referenceType);
		return `${lowerCaseFirst(modelName)}s`;
	}

	@computed
	private get joinProp() {
		const { options } = this.props;
		return options.attributeName.substring(0, options.attributeName.length - 1);
	}

	private _defaultOptions: Model[] = [];

	@computed
	private get defaultOptions(): ComboboxOption<Model>[] {
		return this._defaultOptions.map(r => {
			const { isJoinEntity, disableDefaultOptionRemoval } = this.props;

			r[crudId] = r.id;
			return {
				display: isJoinEntity ? r[this.joinProp].getDisplayName() : r.getDisplayName(),
				value: r,
				isFixed: disableDefaultOptionRemoval,
			};
		});
	}

	@computed
	public get resolveFunc() {
		const { referenceResolveFunction, model, referenceType: ReferenceType } = this.props;

		if (referenceResolveFunction) {
			return _.partial(referenceResolveFunction, _, { model: model });
		}

		const query = getFetchAllQuery(ReferenceType);
		return () => store.apolloClient.query({ query: query, fetchPolicy: 'network-only' })
			.then(data => {
				const associatedObjects: any[] = data[this.modelName];
				let comboOptions: dropdownData = [];
				if (associatedObjects) {
					comboOptions = associatedObjects.map(obj => new ReferenceType(obj))
						.map(obj => ({ display: obj.getDisplayName(), value: obj }));
				}
				return comboOptions;
			});
	}

	constructor(props: IAttributeReferenceComboboxProps<T>) {
		super(props);

		const { model, options } = this.props;

		const modelProp: Model[] | undefined = model[options.attributeName];
		if (Array.isArray(modelProp)) {
			this._defaultOptions = [...modelProp];
		}
	}

	public mutateOptions = (query: string | string[]): Promise<ComboboxOption<Model>[]> => {
		const { isJoinEntity } = this.props;
		return this.resolveFunc(query)
			.then(result => {
				let data = result;
				if (this.props.model.id !== null && this.props.model.id !== undefined) {
					data = data.filter(d => d.value.id !== this.props.model.id);
				}

				runInAction(() => {
					this.options = _.unionBy(
						this.options,
						this.props.model[this.props.options.attributeName],
						data.map(x => x.value),
						isJoinEntity ? `${this.joinProp}Id` : 'id',
					);
				});

				return data.map(x => {
					const option = {
						display: isJoinEntity ? x.value[this.joinProp].getDisplayName() : x.value.getDisplayName(),
						value: x.value,
						isFixed: false,
					};

					if (this.props.disableDefaultOptionRemoval) {
						if (_.find(this.defaultOptions, x.value)) {
							option.isFixed = true;
						}
					}

					return option;
				});
			});
	}

	public getOptions = () => {
		return AwesomeDebouncePromise(this.mutateOptions, 500);
	}

	@action
	private updateData = (data: dropdownData) => {
		this.requestState = {
			state: 'success',
			data,
		};
	}

	@action
	private errorData = () => {
		this.requestState = {
			state: 'error',
		};
	}

	@action
	private getInitialOption = (): Promise<ComboboxOption<Model>[]> => {
		return this.mutateOptions('')
			.then(d => {
				return _.unionBy(
					d,
					this.defaultOptions,
					option => {
						if (this.props.isJoinEntity) {
							return option.value
								? option.value[`${[this.props.options.attributeName.slice(0, -1)]}Id`]
								: undefined;
						}

						return option.value ? option.value.id : undefined;
					},
				);
			})
			.catch(() => this.defaultOptions);
	};

	public render() {
		const {
			isReadonly,
			isRequired,
			model,
			onAfterChange,
			className,
			errors,
			options,
			isJoinEntity,
		} = this.props;

		return (
			<MultiCombobox<T, Model>
				label={options.displayName}
				model={model}
				modelProperty={options.attributeName}
				options={this.getOptions()}
				errors={errors}
				className={className}
				isDisabled={isReadonly}
				onAfterChange={onAfterChange}
				isRequired={isRequired}
				initialOptions={this.getInitialOption}
				getOptionValue={option => {
					if (isJoinEntity) {
						return option ? option[options.attributeName.slice(0, -1)].id : undefined;
					}

					return option ? option.id : undefined;
				}}
				inputProps={{
					header: 'Type to search for entities',
				}}
			/>
		);
	}
}
