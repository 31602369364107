import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import BillingPage from './BillingPage';
import NewOrgForm from './NewOrgForm';
import NewUserForm from './NewUserForm';

export default function AddNew() {
	const { tab } = useParams<{ tab: 'organisations' | 'users' }>();

	const tabName = tab.charAt(0).toUpperCase() + tab.slice(1);

	return (
		<BillingPage
			crumbs={[{ name: tabName, path: `/admin/billing/${tab}` }, { name: `Add new ${tab.slice(0, -1)}` }]}
		>
			<Content>
				{tab === 'organisations' ? <NewOrgForm /> : <NewUserForm />}
			</Content>
		</BillingPage>
	);
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;
