import {
	IOrganisationUserEntityAttributes,
	OrganisationUserEntity,
	UserEntity,
} from 'Models/Entities';
import OrganisationEntity from 'Models/Entities/OrganisationEntity';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BillingService from 'Services/Api/BillingService';
import styled from 'styled-components';
import { Button, Display } from 'Views/Components/Button/Button';
import Spinner from 'Views/Components/Spinner/Spinner';
import BillingPage from './BillingPage';
import OrganisationForm from './OrganisationForm';
import rightPanelStore from './RightPanelStore';
import alert from 'Util/ToastifyUtils';
import UsersTable from './UsersTable';
import EntitySearch from './EntitySearch';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export default function ViewOrganisation() {
	const [organisation, setOrganisation] = useState<OrganisationEntity>();
	const [users, setUsers] = useState<IOrganisationUserEntityAttributes[]>();

	const { orgId } = useParams<{ orgId: string }>();

	useEffect(() => {
		if (!orgId) return;
		BillingService.getOrganisation(orgId).then(org => setOrganisation(new OrganisationEntity(org)));
		BillingService.getOrganisationUsersByOrganisation(orgId).then(subs => setUsers(subs.data));
	}, [orgId]);

	const addUserToOrganisation = (user: UserEntity) => {
		const newOrganisationUserModel = new OrganisationUserEntity({
			organisationId: orgId,
			userId: user.id,
		});
		newOrganisationUserModel.save().then(() => {
			BillingService.getOrganisationUsersByOrganisation(orgId).then(subs => setUsers(subs.data));
		});
	};

	const removeUserFromOrganisation = async (
		user: IOrganisationUserEntityAttributes,
	) => {
		if (!user.id) return;
		BillingService.deleteOrganisationUser(user.id).then(() => {
			setUsers(currentUsers => currentUsers ? currentUsers.filter(u => u.id !== user.id) : []);
		});
	};

	if (!organisation || users === undefined) {
		return <Spinner />;
	}

	return (
		<BillingPage
			crumbs={[
				{ name: 'Organisations', path: '/admin/billing/organisations' },
				{ name: organisation.name },
			]}
		>
			<Content>
				<div className="left-column">
					<Header>
						<h3>Organisation users</h3>
						<Button
							display={Display.Solid}
							className="new-btn"
							onClick={e => {
								e.stopPropagation();
								rightPanelStore.setContent(
									<>
										<Header>
											<h3>Add user to organisation</h3>
										</Header>
										<EntitySearch<UserEntity>
											onSelect={org => {
												rightPanelStore.setContent(null);
												addUserToOrganisation(org);
											}}
											currentEntitys={users.map(x => new UserEntity(x.user))}
											runSearch={(searchTerm: string) => {
												return AwesomeDebouncePromise(
													() => UserEntity.fetch<UserEntity>({
														args: new UserEntity().getSearchConditions(searchTerm),
														take: 50,
													}),
													500,
												)();
											}}
										/>
									</>,
								);
							}}
						>
							Add User
						</Button>
					</Header>
					<UsersTable users={users} onRemoveUser={removeUserFromOrganisation} />
				</div>
				<div>
					<Header>
						<h3>Organisation</h3>
					</Header>
					<OrganisationForm
						organisation={organisation}
						submit={{
							label: 'Save organisation',
							action: async org => {
								await org.save();
								alert('Organisation saved', 'success');
							},
						}}
					/>
				</div>
			</Content>
		</BillingPage>
	);
}

const Content = styled.div`
	display: flex;

	.left-column {
		flex-grow: 1;
		margin-right: 2rem;
	}
`;

const Header = styled.div`
	display: flex;
	margin-bottom: 1rem;

	h3 {
		margin: 0;
	}

	.new-btn {
		margin-left: auto;
	}
`;
