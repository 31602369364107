import { IOrganisationUserEntityAttributes } from 'Models/Entities';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import { EmptyState, StyledTable } from './Common';

interface UsersTableProps {
	users: IOrganisationUserEntityAttributes[];
	onRemoveUser: (user: IOrganisationUserEntityAttributes) => void;
}

export default function UsersTable(props: UsersTableProps) {
	const { users, onRemoveUser } = props;

	const history = useHistory();

	if (users.length === 0) {
		return <EmptyState>No users connected to this organisation</EmptyState>;
	}

	return (
		<StyledTable clickableRows>
			<thead>
				<tr>
					<th>User name</th>
					<th>Added to organisation</th>
					<th>User created</th>
					<th style={{ width: '1%' }} />
				</tr>
			</thead>
			<tbody>
				{
					users.map(user => (
						<tr key={user.id} onClick={() => history.push(`/admin/billing/user/${user.user.id}`)}>
							<td>{user.user.name}</td>
							<td>{moment(user.created).format('L')}</td>
							<td>{moment(user.user.created).format('L')}</td>
							<td>
								<Button
									sizes={Sizes.Small}
									colors={Colors.Error}
									display={Display.Solid}
									onClick={e => {
										e.stopPropagation();
										onRemoveUser(user);
									}}
								>Remove
								</Button>
							</td>
						</tr>
					))
				}
			</tbody>
		</StyledTable>
	);
}
