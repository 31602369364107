/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSnippetEntity from 'Models/Security/Acl/VisitorsSnippetEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISnippetEntityAttributes extends IModelAttributes {
	iconClass: string;
	iconColour: string;
	description: string;
	diagram: string;
	name: string;

	metamodelVersionId?: string;
	metamodelVersion?: Models.MetamodelVersionEntity | Models.IMetamodelVersionEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SnippetEntity', 'Snippet')
// % protected region % [Customise your entity metadata here] end
export default class SnippetEntity extends Model
	implements ISnippetEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSnippetEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Icon Class'] off begin
	@observable
	@attribute<SnippetEntity, string>()
	@CRUD({
		name: 'Icon Class',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public iconClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'Icon Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon Colour'] off begin
	@observable
	@attribute<SnippetEntity, string>()
	@CRUD({
		name: 'Icon Colour',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public iconColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Icon Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<SnippetEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Diagram'] on begin
	/**
	 * JSON
	 */
	@observable
	@attribute<SnippetEntity, string>()
	@CRUD({
		name: 'Diagram',
		displayType: 'textarea',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public diagram: string;
	// % protected region % [Modify props to the crud options here for attribute 'Diagram'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<SnippetEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * [CASCADE] Diagram node snippets compatible with this metamodel version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Version',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.MetamodelVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public metamodelVersion: Models.MetamodelVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] on begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 70,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISnippetEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISnippetEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.iconClass !== undefined) {
				this.iconClass = attributes.iconClass;
			}
			if (attributes.iconColour !== undefined) {
				this.iconColour = attributes.iconColour;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.diagram !== undefined) {
				this.diagram = attributes.diagram;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.metamodelVersionId !== undefined) {
				this.metamodelVersionId = attributes.metamodelVersionId;
			}
			if (attributes.metamodelVersion !== undefined) {
				if (attributes.metamodelVersion === null) {
					this.metamodelVersion = attributes.metamodelVersion;
				} else if (attributes.metamodelVersion instanceof Models.MetamodelVersionEntity) {
					this.metamodelVersion = attributes.metamodelVersion;
					this.metamodelVersionId = attributes.metamodelVersion.id;
				} else {
					this.metamodelVersion = new Models.MetamodelVersionEntity(attributes.metamodelVersion);
					this.metamodelVersionId = this.metamodelVersion.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		metamodelVersion {
			${Models.MetamodelVersionEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SnippetEntity.prototype, 'created');
CRUD(modifiedAttr)(SnippetEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
