/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDiagramEntity from 'Models/Security/Acl/VisitorsDiagramEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getIdCrudOptions } from "Util/EntityUtils";
// % protected region % [Add any further imports here] end

export interface IDiagramEntityAttributes extends IModelAttributes {
	diagramData: string;

	diagramCheckpoints: Array<
		| Models.DiagramCheckpointEntity
		| Models.IDiagramCheckpointEntityAttributes
	>;
	diagramModel?: Models.DiagramModelEntity |
		Models.IDiagramModelEntityAttributes;
	diagramNodes: Array<
		| Models.DiagramNodeEntity
		| Models.IDiagramNodeEntityAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	metamodel?: Models.MetamodelEntity |
		Models.IMetamodelEntityAttributes;
	pipeline?: Models.PipelineEntity |
		Models.IPipelineEntityAttributes;
	previewMetamodel?: Models.MetamodelEntity |
		Models.IMetamodelEntityAttributes;
	visualLibrary?: Models.VisualLibraryEntity |
		Models.IVisualLibraryEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DiagramEntity', 'Diagram')
// % protected region % [Customise your entity metadata here] end
export default class DiagramEntity extends Model
	implements IDiagramEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDiagramEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Diagram Data'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<DiagramEntity, string>()
	@CRUD({
		name: 'Diagram Data',
		displayType: 'textarea',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public diagramData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Diagram Data'] end

	/**
	 * [CASCADE] 
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Checkpoint'] off begin
		name: 'Diagram Checkpoints',
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.DiagramCheckpointEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramCheckpoints',
			oppositeEntity: () => Models.DiagramCheckpointEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Checkpoint'] end
	})
	public diagramCheckpoints: Models.DiagramCheckpointEntity[] = [];

	/**
	 * [CASCADE] The diagram associated with a project model
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] off begin
		name: 'Diagram Model',
		displayType: 'displayfield',
		order: 30,
		inputProps: {
			displayFunction: (model?: Models.DiagramModelEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] end
	})
	public diagramModel?: Models.DiagramModelEntity;

	/**
	 * [CASCADE] Diagram nodes on this diagram
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Node'] off begin
		name: 'Diagram Nodes',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.DiagramNodeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramNodes',
			oppositeEntity: () => Models.DiagramNodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Node'] end
	})
	public diagramNodes: Models.DiagramNodeEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * [CASCADE] Combined Structural and Mapping Diagram for a Metamodel
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] off begin
		name: 'Metamodel',
		displayType: 'displayfield',
		order: 60,
		inputProps: {
			displayFunction: (model?: Models.MetamodelEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] end
	})
	public metamodel?: Models.MetamodelEntity;

	/**
	 * [CASCADE] 
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] off begin
		name: 'Pipeline',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.PipelineEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelines',
			oppositeEntity: () => Models.PipelineEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] end
	})
	public pipeline?: Models.PipelineEntity;

	/**
	 * [CASCADE] Diagram to preview modelling with the metamodel
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Preview Metamodel'] off begin
		name: 'Preview Metamodel',
		displayType: 'displayfield',
		order: 80,
		inputProps: {
			displayFunction: (model?: Models.MetamodelEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Preview Metamodel'] end
	})
	public previewMetamodel?: Models.MetamodelEntity;

	/**
	 * [CASCADE] Diagram in which visual libraries are built
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] off begin
		name: 'Visual Library',
		displayType: 'displayfield',
		order: 90,
		inputProps: {
			displayFunction: (model?: Models.VisualLibraryEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] end
	})
	public visualLibrary?: Models.VisualLibraryEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDiagramEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDiagramEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.diagramData !== undefined) {
				this.diagramData = attributes.diagramData;
			}
			if (attributes.diagramCheckpoints !== undefined && Array.isArray(attributes.diagramCheckpoints)) {
				for (const model of attributes.diagramCheckpoints) {
					if (model instanceof Models.DiagramCheckpointEntity) {
						this.diagramCheckpoints.push(model);
					} else {
						this.diagramCheckpoints.push(new Models.DiagramCheckpointEntity(model));
					}
				}
			}
			if (attributes.diagramModel !== undefined) {
				if (attributes.diagramModel === null) {
					this.diagramModel = attributes.diagramModel;
				} else if (attributes.diagramModel instanceof Models.DiagramModelEntity) {
					this.diagramModel = attributes.diagramModel;
				} else {
					this.diagramModel = new Models.DiagramModelEntity(attributes.diagramModel);
				}
			}
			if (attributes.diagramNodes !== undefined && Array.isArray(attributes.diagramNodes)) {
				for (const model of attributes.diagramNodes) {
					if (model instanceof Models.DiagramNodeEntity) {
						this.diagramNodes.push(model);
					} else {
						this.diagramNodes.push(new Models.DiagramNodeEntity(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.metamodel !== undefined) {
				if (attributes.metamodel === null) {
					this.metamodel = attributes.metamodel;
				} else if (attributes.metamodel instanceof Models.MetamodelEntity) {
					this.metamodel = attributes.metamodel;
				} else {
					this.metamodel = new Models.MetamodelEntity(attributes.metamodel);
				}
			}
			if (attributes.pipeline !== undefined) {
				if (attributes.pipeline === null) {
					this.pipeline = attributes.pipeline;
				} else if (attributes.pipeline instanceof Models.PipelineEntity) {
					this.pipeline = attributes.pipeline;
				} else {
					this.pipeline = new Models.PipelineEntity(attributes.pipeline);
				}
			}
			if (attributes.previewMetamodel !== undefined) {
				if (attributes.previewMetamodel === null) {
					this.previewMetamodel = attributes.previewMetamodel;
				} else if (attributes.previewMetamodel instanceof Models.MetamodelEntity) {
					this.previewMetamodel = attributes.previewMetamodel;
				} else {
					this.previewMetamodel = new Models.MetamodelEntity(attributes.previewMetamodel);
				}
			}
			if (attributes.visualLibrary !== undefined) {
				if (attributes.visualLibrary === null) {
					this.visualLibrary = attributes.visualLibrary;
				} else if (attributes.visualLibrary instanceof Models.VisualLibraryEntity) {
					this.visualLibrary = attributes.visualLibrary;
				} else {
					this.visualLibrary = new Models.VisualLibraryEntity(attributes.visualLibrary);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		diagramCheckpoints {
			${Models.DiagramCheckpointEntity.getAttributes().join('\n')}
		}
		diagramModel {
			${Models.DiagramModelEntity.getAttributes().join('\n')}
		}
		diagramNodes {
			${Models.DiagramNodeEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		metamodel {
			${Models.MetamodelEntity.getAttributes().join('\n')}
		}
		pipeline {
			${Models.PipelineEntity.getAttributes().join('\n')}
		}
		previewMetamodel {
			${Models.MetamodelEntity.getAttributes().join('\n')}
		}
		visualLibrary {
			${Models.VisualLibraryEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			diagramCheckpoints: {},
			diagramNodes: {},
			pipeline: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'diagramCheckpoints',
							'pipeline',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
const idAttr = getIdCrudOptions();
CRUD(createdAttr)(DiagramEntity.prototype, 'created');
CRUD(modifiedAttr)(DiagramEntity.prototype, 'modified');
CRUD(idAttr)(DiagramEntity.prototype, 'id');
// % protected region % [Modify the create and modified CRUD attributes here] end
