import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { AuthUserInput } from 'Services/Api/BillingService';
import { Button, Display } from 'Views/Components/Button/Button';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { SideForm } from './Common';

interface UserFormProps {
	user?: AuthUserInput;
	submit: {
		action: (userModel: AuthUserInput) => void;
		label: string;
	}
	hidePassword?: boolean;
	hideUserDetails?: boolean;
	onCancel?: () => void;
}

function UserForm(props: UserFormProps) {
	const {
		user, submit, onCancel, hidePassword, hideUserDetails,
	} = props;

	const [loading, setLoading] = useState(false);

	if (!user) return null;

	const onSubmit = async (e: React.FormEvent<Element>) => {
		e.preventDefault();
		setLoading(true);
		await submit.action(user);
		setLoading(false);
	};

	return (
		<SideForm onSubmit={onSubmit}>
			{!hideUserDetails && (
				<>
					<TextField
						model={user}
						modelProperty="fullName"
						label="User name"
					/>
					<TextField
						model={user}
						modelProperty="email"
						label="Email address"
					/>
				</>
			)}
			{!hidePassword && (
				<TextField
					model={user}
					modelProperty="password"
					label="Password"
					inputProps={{
						type: 'password',
					}}
				/>
			)}
			<ButtonGroup>
				<Button
					display={Display.Solid}
					type="submit"
					disabled={loading || (!hidePassword && !user.password)}
				>
					{submit.label}
				</Button>
				{onCancel && <Button onClick={onCancel}>Cancel</Button>}
			</ButtonGroup>
		</SideForm>
	);
}

export default observer(UserForm);
