/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';

// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

export interface IFieldSet {
	/** The unique id for FieldSet component which will be used as key */
	id: string;
	/** The displayed content of the fildset legend */
	name: string;
	/** The extra class for fieldSet */
	className?: string;
	/** Whether to display the name of the group */
	showName?: boolean;
	/** Children */
	children: React.ReactNode;
}

@observer
export class FieldSet extends React.Component<IFieldSet> {
	render() {
		const {
			name,
			children,
			id,
			className,
		} = this.props;

		return (
			<fieldset key={id} className={className}>
				{name.length > 0 ? <legend>{name}</legend> : <></>}
				<div>{children}</div>
			</fieldset>
		);
	}
	// % protected region % [Add extra methods here] off begin
	// % protected region % [Add extra methods here] end
}
