import { IModelAttributes } from 'Models/Model';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface EntitySearchProps<T> {
	onSelect: (entity: T) => void;
	runSearch: (searchTerm: string) => Promise<T[]>;
	currentEntitys: T[];
}

type NamedEntity = IModelAttributes & { name: string };

export default function EntitySearch<T extends NamedEntity>(props: EntitySearchProps<T>) {
	const { onSelect, currentEntitys, runSearch } = props;

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [entitys, setEntitys] = useState<T[]>([]);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 250);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		runSearch(searchTerm).then(setEntitys);
	}, [searchTerm, runSearch]);

	const submitSelection = (entity: T) => {
		setSubmitting(true);
		onSelect(entity);
	};

	return (
		<>
			<Content>
				<div className="input-group input-group-block">
					<label>Entity name</label>
					<Input
						ref={inputRef}
						type="text"
						placeholder="Search for an entity"
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className="list-group">
					{
						entitys.filter(y => !currentEntitys.some(z => z.id === y.id)).map((entity, index) => (
							<div
								key={entity.id}
								className="list-group-item"
								onClick={() => submitSelection(entity)}
								tabIndex={index}
								onKeyDown={() => submitSelection(entity)}
								role="button"
							>
								{entity.name}
							</div>
						))
					}
				</div>
			</Content>
			{submitting && (
				<LoadingPanel>
					<span>Adding...</span>
				</LoadingPanel>
			)}
		</>
	);
}

const Input = styled.input`
	&:focus, &:hover {
		background-color: white!important;
	}
`;

const LoadingPanel = styled.div`
	position: absolute;
	inset: 0;
	background: #00000040;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Content = styled.div`
	position: relative;

	.list-group {
		display: flex;
		flex-direction: column;

		.list-group-item {
			background: white;
			border: none;
			border-radius: 6px;
			padding: 1rem;
			margin: 0.25rem 0;
			border: 1px solid transparent;

			&:hover {
				cursor: pointer;
				border: 1px solid #b8d9ff;
			}
		}
	}
`;
