/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type authMethod =
	// % protected region % [Override authMethod keys here] off begin
	'SSH' |
	'OAUTH' |
	'BASIC';
	// % protected region % [Override authMethod keys here] end

export const authMethodOptions: { [key in authMethod]: string } = {
	// % protected region % [Override authMethod display fields here] off begin
	SSH: 'SSH',
	OAUTH: 'OAUTH',
	BASIC: 'BASIC',
	// % protected region % [Override authMethod display fields here] end
};

export type loggingLevel =
	// % protected region % [Override loggingLevel keys here] off begin
	'INFO' |
	'DEBUG' |
	'LIFECYCLE';
	// % protected region % [Override loggingLevel keys here] end

export const loggingLevelOptions: { [key in loggingLevel]: string } = {
	// % protected region % [Override loggingLevel display fields here] off begin
	INFO: 'INFO',
	DEBUG: 'DEBUG',
	LIFECYCLE: 'LIFECYCLE',
	// % protected region % [Override loggingLevel display fields here] end
};

export type repositoryProvider =
	// % protected region % [Override repositoryProvider keys here] off begin
	'GITHUB' |
	'GITLAB' |
	'BITBUCKET' |
	'GENERIC';
	// % protected region % [Override repositoryProvider keys here] end

export const repositoryProviderOptions: { [key in repositoryProvider]: string } = {
	// % protected region % [Override repositoryProvider display fields here] off begin
	GITHUB: 'GITHUB',
	GITLAB: 'GITLAB',
	BITBUCKET: 'BITBUCKET',
	GENERIC: 'GENERIC',
	// % protected region % [Override repositoryProvider display fields here] end
};

export type status =
	// % protected region % [Override status keys here] off begin
	'RUNNING' |
	'SUCCESS' |
	'STALLED' |
	'ERROR' |
	'SKIPPED' |
	'INITIALIZING' |
	'ABORTED' |
	'QUEUED';
	// % protected region % [Override status keys here] end

export const statusOptions: { [key in status]: string } = {
	// % protected region % [Override status display fields here] off begin
	RUNNING: 'RUNNING',
	SUCCESS: 'SUCCESS',
	STALLED: 'STALLED',
	ERROR: 'ERROR',
	SKIPPED: 'SKIPPED',
	INITIALIZING: 'INITIALIZING',
	ABORTED: 'ABORTED',
	QUEUED: 'QUEUED',
	// % protected region % [Override status display fields here] end
};

// % protected region % [Add any extra enums here] off begin
// % protected region % [Add any extra enums here] end
