import React from 'react';
import styled from 'styled-components';
import Crumbs, { ICrumb } from './Crumbs';

interface BillingPageProps {
	children: React.ReactNode;
	crumbs?: ICrumb[];
}

export default function BillingPage(props: BillingPageProps) {
	const { children, crumbs } = props;

	const pageCrumbs = crumbs || [];
	pageCrumbs.unshift({
		name: 'Billing',
		path: '/admin/billing',
	});

	return (
		<Content>
			<Crumbs crumbs={pageCrumbs} />
			{children}
		</Content>
	);
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;
