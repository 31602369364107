import UserEntity from 'Models/Entities/UserEntity';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'Views/Components/Button/Button';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { TextField } from 'Views/Components/TextBox/TextBox';

export default function NewUserForm() {
	const [newUser, setNewUser] = useState<UserEntity>();

	useEffect(() => {
		setNewUser(new UserEntity());
	}, []);

	const history = useHistory();

	const onSubmit = async (e: React.FormEvent<Element>) => {
		e.preventDefault();
		await newUser?.save();
		history.push(`/admin/billing/user/${newUser?.id}`);
	};

	if (!newUser) return null;

	return (
		<form onSubmit={onSubmit}>
			<TextField
				model={newUser}
				modelProperty="name"
				label="User name"
			/>
			<TextField
				model={newUser}
				modelProperty="email"
				label="Email address"
			/>
			<ButtonGroup>
				<Button type="submit">Submit</Button>
				<Button onClick={() => history.push('/admin/billing/users')}>Cancel</Button>
			</ButtonGroup>
		</form>
	);
}
