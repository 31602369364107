import UserEntity from 'Models/Entities/UserEntity';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BillingService, { AuthUserInput } from 'Services/Api/BillingService';
import Spinner from 'Views/Components/Spinner/Spinner';
import BillingPage from './BillingPage';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import rightPanelStore from './RightPanelStore';
import alert from 'Util/ToastifyUtils';
import styled from 'styled-components';
import UserForm from './UserForm';
import OrganisationUserEntity, { IOrganisationUserEntityAttributes } from 'Models/Entities/OrganisationUserEntity';
import { OrganisationEntity } from 'Models/Entities';
import { EmptyState, StyledTable } from './Common';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import EntitySearch from './EntitySearch';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';

function ViewUser() {
	const [user, setUser] = useState<UserEntity>();
	const [authUser, setAuthUser] = useState<AuthUserInput>();
	const [organisationUsers, setOrganisationUsers] = useState<IOrganisationUserEntityAttributes[]>();

	const { userId } = useParams<{ userId: string }>();

	const history = useHistory();

	useEffect(() => {
		if (!userId) return;
		BillingService.getUser(userId).then(userRes => {
			setUser(new UserEntity(userRes));
			setAuthUser(new AuthUserInput({
				email: userRes.email,
				password: '',
				fullName: userRes.name,
			}));
		});
		BillingService.getOrganisationUsersByUser(userId).then(orgUsers => setOrganisationUsers(orgUsers.data));
	}, [userId]);

	if (!user || organisationUsers === undefined || authUser === undefined) {
		return <Spinner />;
	}

	const addUserToOrganisation = (organisation: OrganisationEntity) => {
		const newOrganisationUserModel = new OrganisationUserEntity({
			organisationId: organisation.id,
			userId: user.id,
		});
		newOrganisationUserModel.save().then(() => {
			BillingService.getOrganisationUsersByUser(user.id).then(orgUsers => setOrganisationUsers(orgUsers.data));
		});
	};

	const removeUserFromOrganisation = async (organisationUser: IOrganisationUserEntityAttributes) => {
		if (!organisationUser.id) return;
		BillingService.deleteOrganisationUser(organisationUser.id).then(() => {
			BillingService.getOrganisationUsersByUser(user.id).then(orgUsers => setOrganisationUsers(orgUsers.data));
		});
	};

	const resetMfa = () => {
		const updatedUser = new AuthUserInput({
			...authUser,
			resetMfa: true,
		});
		BillingService.updateUser(updatedUser, userId).then(() => {
			alert('MFA reset', 'success');
		}).catch(error => {
			alert('MFA reset failed', 'error');
			console.error(error);
		});
	};

	return (
		<BillingPage crumbs={[{ name: 'Users', path: '/admin/billing/users' }, { name: user.name }]}>
			<Content>
				<div className="left-column">
					<Header>
						<h3>Organisations</h3>
						<Button
							display={Display.Solid}
							className="new-btn"
							onClick={e => {
								e.stopPropagation();
								rightPanelStore.setContent(
									<>
										<Header>
											<h3>Add user to organisation</h3>
										</Header>
										<EntitySearch<OrganisationEntity>
											onSelect={org => {
												rightPanelStore.setContent(null);
												addUserToOrganisation(org);
											}}
											currentEntitys={
												organisationUsers.map(x => new OrganisationEntity(x.organisation))
											}
											runSearch={(searchTerm: string) => {
												return AwesomeDebouncePromise(
													() => OrganisationEntity.fetch<OrganisationEntity>({
														args: (new OrganisationEntity())
															.getSearchConditions(searchTerm),
														take: 50,
													}),
													500,
												)();
											}}
										/>
									</>,
								);
							}}
						>
							Add to organisation
						</Button>
					</Header>
					{organisationUsers.length === 0 && <EmptyState>No connected organisations</EmptyState>}
					{organisationUsers.length !== 0 && (
						<StyledTable clickableRows>
							<thead>
								<tr>
									<th>Organisation</th>
									<th>Is admin</th>
									<th style={{ width: '1%' }} />
								</tr>
							</thead>
							<tbody>
								{organisationUsers.map(organisationUser => (
									<tr
										key={organisationUser.id}
										onClick={() => history
											.push(`/admin/billing/organisation/${organisationUser.organisationId}`)}
									>
										<td>{organisationUser.organisation.name}</td>
										<td>{organisationUser.isAdmin ? 'Yes' : 'No'}</td>
										<td>
											<Button
												sizes={Sizes.Small}
												colors={Colors.Error}
												display={Display.Solid}
												onClick={e => {
													e.stopPropagation();
													removeUserFromOrganisation(organisationUser);
												}}
											>
												Remove
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</StyledTable>
					)}
				</div>
				<div>
					<Header>
						<h3>Edit user</h3>
					</Header>
					<UserForm
						hidePassword
						user={authUser}
						submit={{
							label: 'Save user',
							action: async updatedUser => {
								BillingService.updateUser(updatedUser, userId).then(action(res => {
									user.name = updatedUser.fullName;
									user.email = updatedUser.email;
									alert('User saved', 'success');
								}));
							},
						}}
					/>
					<UserForm
						hideUserDetails
						user={authUser}
						submit={{
							label: 'Change password',
							action: async updatedUser => {
								BillingService.updateUser(updatedUser, userId).then(action(() => {
									authUser.password = '';
									alert('Password altered', 'success');
								}));
							},
						}}
					/>
					<ButtonGroup>
						<Button
							display={Display.Solid}
							colors={Colors.Error}
							type="button"
							onClick={resetMfa}
						>
							Reset MFA
						</Button>
					</ButtonGroup>
				</div>
			</Content>
		</BillingPage>
	);
}

export default observer(ViewUser);

const Content = styled.div`
	display: flex;

	.left-column {
		flex-grow: 1;
		margin-right: 2rem;
	}
`;

const Header = styled.div`
	display: flex;
	margin-bottom: 1rem;

	h3 {
		margin: 0;
	}

	.new-btn {
		margin-left: auto;
	}
`;
