import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { IOrganisationEntityAttributes } from 'Models/Entities/OrganisationEntity';
import { IUserEntityAttributes } from 'Models/Entities';
import { IOrganisationUserEntityAttributes } from 'Models/Entities/OrganisationUserEntity';
import { observable } from 'mobx';

export default class BillingService {
	// Get all queries
	static async getOrganisations() {
		const response = await axios
			.get<AxiosGetResponse<IOrganisationEntityAttributes[]>>(`${SERVER_URL}/api/entity/OrganisationEntity`);
		return response.data;
	}

	static async getUsers() {
		const response = await axios
			.get<AxiosGetResponse<IUserEntityAttributes[]>>(`${SERVER_URL}/api/entity/UserEntity`);
		return response.data;
	}

	// Get single queries
	static async getOrganisation(id: string) {
		const response = await axios
			.get<IOrganisationEntityAttributes>(`${SERVER_URL}/api/entity/OrganisationEntity/${id}`);
		return response.data;
	}

	static async getUser(id: string) {
		const response = await axios
			.get<IUserEntityAttributes>(`${SERVER_URL}/api/entity/UserEntity/${id}`);
		return response.data;
	}

	// Get x by y queries
	static async getOrganisationUsersByOrganisation(id: string) {
		const response = await axios
			.get<AxiosGetResponse<IOrganisationUserEntityAttributes[]>>(
				`${SERVER_URL}/api/entity/OrganisationUserEntity/ByOrganisation/${id}`,
			);
		return response.data;
	}

	static async getOrganisationUsersByUser(id: string) {
		const response = await axios
			.get<AxiosGetResponse<IOrganisationUserEntityAttributes[]>>(
				`${SERVER_URL}/api/entity/OrganisationUserEntity/ByUser/${id}`,
			);
		return response.data;
	}

	// Delete queries
	static async deleteOrganisationUser(id: string) {
		const response = await axios.delete(`${SERVER_URL}/api/entity/OrganisationUserEntity/${id}`);
		return response.data;
	}

	// Create queries
	static async createUser(user: AuthUserInput) {
		const response = await axios.post<AuthUser>(`${SERVER_URL}/api/entity/UserEntity/LdapUser`, user);
		return response.data;
	}

	// Update queries
	static async updateUser(user: AuthUserInput, id: string) {
		const response = await axios.put<AuthUser>(`${SERVER_URL}/api/entity/UserEntity/LdapUser/${id}`, user);
		return response.data;
	}
}

interface AxiosGetResponse<T> {
	data: T;
	count: number;
}

export interface IAuthUserInput {
	fullName: string;
	email: string;
	password?: string;
	resetMfa?: boolean;
}

export interface AuthUser {
	givenName: string;
	familyName: string;
	name: string;
	picture: string;
	email: string;
	emailVerified: boolean;
	id: string;
	created: string;
	modified: string;
	lastSuccessfulLogin: string;
	lastFailedLogin: string;
	metadata: Record<string, any>;
	groups: AuthGroup[];
}

interface AuthGroup {
	id: string;
	name: string;
}

export class AuthUserInput implements IAuthUserInput {
	constructor(props: IAuthUserInput) {
		this.fullName = props.fullName;
		this.email = props.email;
		this.password = props.password;
		this.resetMfa = props.resetMfa ?? false;
	}

	@observable
	public fullName: string;

	@observable
	public email: string;

	@observable
	public password?: string;

	@observable
	public resetMfa: boolean = false;
}
