import { IUserEntityAttributes } from 'Models/Entities/UserEntity';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import { NormalTable } from './Common';

interface UserListProps {
	users?: IUserEntityAttributes[];
}

export default function UserList(props: UserListProps) {
	const { users } = props;

	const history = useHistory();

	const navigateToUser = (userId?: string) => {
		if (!userId) return;
		history.push(`/admin/billing/user/${userId}`);
	};

	if (!users) {
		return <div>Loading...</div>;
	}

	return (
		<NormalTable clickableRows>
			<thead>
				<tr>
					<th>User name</th>
					<th>Email</th>
					<th>Created</th>
				</tr>
			</thead>
			<tbody>
				{
					users.map(user => (
						<tr key={user.id} onClick={() => navigateToUser(user.id)}>
							<td>{user.name}</td>
							<td>{user.email}</td>
							<td>{moment(user.created).format('LLL')}</td>
						</tr>
					))
				}
			</tbody>
		</NormalTable>
	);
}
