/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsPipelineRunEntity from 'Models/Security/Acl/VisitorsPipelineRunEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getIdCrudOptions } from "Util/EntityUtils";
// % protected region % [Add any further imports here] end

export interface IPipelineRunEntityAttributes extends IModelAttributes {
	loggingLevel: Enums.loggingLevel;
	exitCode: number;
	started: Date;
	logs: string;
	status: Enums.status;
	statusMessage: string;
	inputs: string;
	finished: Date;

	botVersionId?: string;
	botVersion?: Models.BotVersionEntity | Models.IBotVersionEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	pipelineVersionId: string;
	pipelineVersion: Models.PipelineVersionEntity | Models.IPipelineVersionEntityAttributes;
	runnerId?: string;
	runner?: Models.RunnerEntity | Models.IRunnerEntityAttributes;
	pipelineStepRuns: Array<
		| Models.PipelineStepRunEntity
		| Models.IPipelineStepRunEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PipelineRunEntity', 'Pipeline Run')
// % protected region % [Customise your entity metadata here] end
export default class PipelineRunEntity extends Model
	implements IPipelineRunEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPipelineRunEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Logging Level'] off begin
	@observable
	@attribute<PipelineRunEntity, Enums.loggingLevel>()
	@CRUD({
		name: 'Logging Level',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.loggingLevelOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.loggingLevelOptions),
		displayFunction: (attr: Enums.loggingLevel) => Enums.loggingLevelOptions[attr],
	})
	public loggingLevel: Enums.loggingLevel;
	// % protected region % [Modify props to the crud options here for attribute 'Logging Level'] end

	// % protected region % [Modify props to the crud options here for attribute 'Exit Code'] off begin
	@Validators.Integer()
	@observable
	@attribute<PipelineRunEntity, number>()
	@CRUD({
		name: 'Exit Code',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public exitCode: number;
	// % protected region % [Modify props to the crud options here for attribute 'Exit Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Started'] off begin
	@observable
	@attribute<PipelineRunEntity, Date>()
	@CRUD({
		name: 'Started',
		displayType: 'datetimepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public started: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Started'] end

	// % protected region % [Modify props to the crud options here for attribute 'Logs'] on begin
	@observable
	@attribute<PipelineRunEntity, string>()
	@CRUD({
		name: 'Logs',
		displayType: 'textarea',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public logs: string;
	// % protected region % [Modify props to the crud options here for attribute 'Logs'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@Validators.Required()
	@observable
	@attribute<PipelineRunEntity, Enums.status>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.statusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.statusOptions),
		displayFunction: (attr: Enums.status) => Enums.statusOptions[attr],
	})
	public status: Enums.status;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status Message'] on begin
	@observable
	@attribute<PipelineRunEntity, string>()
	@CRUD({
		name: 'Status Message',
		displayType: 'textarea',
		headerColumn: false,
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public statusMessage: string;
	// % protected region % [Modify props to the crud options here for attribute 'Status Message'] end

	// % protected region % [Modify props to the crud options here for attribute 'Inputs'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<PipelineRunEntity, string>()
	@CRUD({
		name: 'Inputs',
		displayType: 'textarea',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public inputs: string;
	// % protected region % [Modify props to the crud options here for attribute 'Inputs'] end

	// % protected region % [Modify props to the crud options here for attribute 'Finished'] off begin
	@observable
	@attribute<PipelineRunEntity, Date>()
	@CRUD({
		name: 'Finished',
		displayType: 'datetimepicker',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public finished: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Finished'] end

	/**
	 * [NULLIFY] Pipeline runs  executed by this bot version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] off begin
		name: 'Bot Version',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.BotVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] end
	})
	public botVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public botVersion: Models.BotVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * [CASCADE] The details of each instance of this pipeline version being executed
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] off begin
		name: 'Pipeline Version',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.PipelineVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] end
	})
	public pipelineVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public pipelineVersion: Models.PipelineVersionEntity;

	/**
	 * [NULLIFY] 
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Runner'] off begin
		name: 'Runner',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.RunnerEntity,
		// % protected region % [Modify props to the crud options here for reference 'Runner'] end
	})
	public runnerId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public runner: Models.RunnerEntity;

	/**
	 * [CASCADE] The steps executed as part of this pipeline run
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Step Run'] off begin
		name: 'Pipeline Step Runs',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.PipelineStepRunEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineStepRuns',
			oppositeEntity: () => Models.PipelineStepRunEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Step Run'] end
	})
	public pipelineStepRuns: Models.PipelineStepRunEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPipelineRunEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPipelineRunEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.loggingLevel !== undefined) {
				this.loggingLevel = attributes.loggingLevel;
			}
			if (attributes.exitCode !== undefined) {
				this.exitCode = attributes.exitCode;
			}
			if (attributes.started !== undefined) {
				if (attributes.started === null) {
					this.started = attributes.started;
				} else {
					this.started = moment(attributes.started).toDate();
				}
			}
			if (attributes.logs !== undefined) {
				this.logs = attributes.logs;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.statusMessage !== undefined) {
				this.statusMessage = attributes.statusMessage;
			}
			if (attributes.inputs !== undefined) {
				this.inputs = attributes.inputs;
			}
			if (attributes.finished !== undefined) {
				if (attributes.finished === null) {
					this.finished = attributes.finished;
				} else {
					this.finished = moment(attributes.finished).toDate();
				}
			}
			if (attributes.botVersionId !== undefined) {
				this.botVersionId = attributes.botVersionId;
			}
			if (attributes.botVersion !== undefined) {
				if (attributes.botVersion === null) {
					this.botVersion = attributes.botVersion;
				} else if (attributes.botVersion instanceof Models.BotVersionEntity) {
					this.botVersion = attributes.botVersion;
					this.botVersionId = attributes.botVersion.id;
				} else {
					this.botVersion = new Models.BotVersionEntity(attributes.botVersion);
					this.botVersionId = this.botVersion.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.pipelineVersionId !== undefined) {
				this.pipelineVersionId = attributes.pipelineVersionId;
			}
			if (attributes.pipelineVersion !== undefined) {
				if (attributes.pipelineVersion === null) {
					this.pipelineVersion = attributes.pipelineVersion;
				} else if (attributes.pipelineVersion instanceof Models.PipelineVersionEntity) {
					this.pipelineVersion = attributes.pipelineVersion;
					this.pipelineVersionId = attributes.pipelineVersion.id;
				} else {
					this.pipelineVersion = new Models.PipelineVersionEntity(attributes.pipelineVersion);
					this.pipelineVersionId = this.pipelineVersion.id;
				}
			}
			if (attributes.runnerId !== undefined) {
				this.runnerId = attributes.runnerId;
			}
			if (attributes.runner !== undefined) {
				if (attributes.runner === null) {
					this.runner = attributes.runner;
				} else if (attributes.runner instanceof Models.RunnerEntity) {
					this.runner = attributes.runner;
					this.runnerId = attributes.runner.id;
				} else {
					this.runner = new Models.RunnerEntity(attributes.runner);
					this.runnerId = this.runner.id;
				}
			}
			if (attributes.pipelineStepRuns !== undefined && Array.isArray(attributes.pipelineStepRuns)) {
				for (const model of attributes.pipelineStepRuns) {
					if (model instanceof Models.PipelineStepRunEntity) {
						this.pipelineStepRuns.push(model);
					} else {
						this.pipelineStepRuns.push(new Models.PipelineStepRunEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		botVersion {
			${Models.BotVersionEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		pipelineVersion {
			${Models.PipelineVersionEntity.getAttributes().join('\n')}
		}
		runner {
			${Models.RunnerEntity.getAttributes().join('\n')}
		}
		pipelineStepRuns {
			${Models.PipelineStepRunEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			pipelineStepRuns: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
const idAttr = getIdCrudOptions();
CRUD(createdAttr)(PipelineRunEntity.prototype, 'created');
CRUD(modifiedAttr)(PipelineRunEntity.prototype, 'modified');
CRUD(idAttr)(PipelineRunEntity.prototype, 'id');
// % protected region % [Modify the create and modified CRUD attributes here] end
