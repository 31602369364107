/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsRepositoryCredentialsEntity from 'Models/Security/Acl/VisitorsRepositoryCredentialsEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRepositoryCredentialsEntityAttributes extends IModelAttributes {
	data: string;
	provider: Enums.repositoryProvider;
	path: string;
	authMethod: Enums.authMethod;
	name: string;

	repositories: Array<
		| Models.RepositoryEntity
		| Models.IRepositoryEntityAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RepositoryCredentialsEntity', 'Repository Credentials')
// % protected region % [Customise your entity metadata here] end
export default class RepositoryCredentialsEntity extends Model
	implements IRepositoryCredentialsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRepositoryCredentialsEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Data'] off begin
	/**
	 * Encrypted JSON
	 */
	@observable
	@attribute<RepositoryCredentialsEntity, string>()
	@CRUD({
		name: 'Data',
		displayType: 'textarea',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public data: string;
	// % protected region % [Modify props to the crud options here for attribute 'Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Provider'] off begin
	@observable
	@attribute<RepositoryCredentialsEntity, Enums.repositoryProvider>()
	@CRUD({
		name: 'Provider',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.repositoryProviderOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.repositoryProviderOptions),
		displayFunction: (attr: Enums.repositoryProvider) => Enums.repositoryProviderOptions[attr],
	})
	public provider: Enums.repositoryProvider;
	// % protected region % [Modify props to the crud options here for attribute 'Provider'] end

	// % protected region % [Modify props to the crud options here for attribute 'Path'] off begin
	@Validators.Required()
	@observable
	@attribute<RepositoryCredentialsEntity, string>()
	@CRUD({
		name: 'Path',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public path: string;
	// % protected region % [Modify props to the crud options here for attribute 'Path'] end

	// % protected region % [Modify props to the crud options here for attribute 'Auth Method'] off begin
	@observable
	@attribute<RepositoryCredentialsEntity, Enums.authMethod>()
	@CRUD({
		name: 'Auth Method',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.authMethodOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.authMethodOptions),
		displayFunction: (attr: Enums.authMethod) => Enums.authMethodOptions[attr],
	})
	public authMethod: Enums.authMethod;
	// % protected region % [Modify props to the crud options here for attribute 'Auth Method'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<RepositoryCredentialsEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * [RESTRICT] Repositories which can be accessed using these credentials
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] off begin
		name: 'Repositories',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.RepositoryEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'repositories',
			oppositeEntity: () => Models.RepositoryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] end
	})
	public repositories: Models.RepositoryEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRepositoryCredentialsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRepositoryCredentialsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.data !== undefined) {
				this.data = attributes.data;
			}
			if (attributes.provider !== undefined) {
				this.provider = attributes.provider;
			}
			if (attributes.path !== undefined) {
				this.path = attributes.path;
			}
			if (attributes.authMethod !== undefined) {
				this.authMethod = attributes.authMethod;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.repositories !== undefined && Array.isArray(attributes.repositories)) {
				for (const model of attributes.repositories) {
					if (model instanceof Models.RepositoryEntity) {
						this.repositories.push(model);
					} else {
						this.repositories.push(new Models.RepositoryEntity(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		repositories {
			${Models.RepositoryEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			repositories: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'repositories',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RepositoryCredentialsEntity.prototype, 'created');
CRUD(modifiedAttr)(RepositoryCredentialsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
