/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsProjectEntity from 'Models/Security/Acl/VisitorsProjectEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProjectEntityAttributes extends IModelAttributes {
	slug: string;
	name: string;

	botVersions: Array<
		| Models.ProjectBotVersion
		| Models.IProjectBotVersionAttributes
	>;
	modelVersions: Array<
		| Models.ProjectModelVersion
		| Models.IProjectModelVersionAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	repositories: Array<
		| Models.ProjectRepositorie
		| Models.IProjectRepositorieAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProjectEntity', 'Project')
// % protected region % [Customise your entity metadata here] end
export default class ProjectEntity extends Model
	implements IProjectEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsProjectEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Slug'] off begin
	@Validators.Required()
	@Validators.Regex(/^[A-Za-z0-9]*$/, 'You can only use alphanumeric characters in this field')
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Slug',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public slug: string;
	// % protected region % [Modify props to the crud options here for attribute 'Slug'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * Bot versions a project uses
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] off begin
		name: 'Bot Version',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProjectBotVersion,
		optionEqualFunc: makeJoinEqualsFunc('botVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'projectEntity',
			oppositeEntityName: 'botVersionEntity',
			relationName: 'project',
			relationOppositeName: 'botVersion',
			entity: () => Models.ProjectEntity,
			joinEntity: () => Models.ProjectBotVersion,
			oppositeEntity: () => Models.BotVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] end
	})
	public botVersions: Models.ProjectBotVersion[] = [];

	/**
	 * The models used by the project
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Model Version'] off begin
		name: 'Model Version',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProjectModelVersion,
		optionEqualFunc: makeJoinEqualsFunc('modelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'projectEntity',
			oppositeEntityName: 'diagramModelVersionEntity',
			relationName: 'project',
			relationOppositeName: 'modelVersion',
			entity: () => Models.ProjectEntity,
			joinEntity: () => Models.ProjectModelVersion,
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Model Version'] end
	})
	public modelVersions: Models.ProjectModelVersion[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * The repositories used by the project
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] off begin
		name: 'Repositorie',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProjectRepositorie,
		optionEqualFunc: makeJoinEqualsFunc('repositorieId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'projectEntity',
			oppositeEntityName: 'repositoryEntity',
			relationName: 'project',
			relationOppositeName: 'repositorie',
			entity: () => Models.ProjectEntity,
			joinEntity: () => Models.ProjectRepositorie,
			oppositeEntity: () => Models.RepositoryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] end
	})
	public repositories: Models.ProjectRepositorie[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProjectEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProjectEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.slug !== undefined) {
				this.slug = attributes.slug;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.botVersions !== undefined && Array.isArray(attributes.botVersions)) {
				for (const model of attributes.botVersions) {
					if (model instanceof Models.ProjectBotVersion) {
						this.botVersions.push(model);
					} else {
						this.botVersions.push(new Models.ProjectBotVersion(model));
					}
				}
			}
			if (attributes.modelVersions !== undefined && Array.isArray(attributes.modelVersions)) {
				for (const model of attributes.modelVersions) {
					if (model instanceof Models.ProjectModelVersion) {
						this.modelVersions.push(model);
					} else {
						this.modelVersions.push(new Models.ProjectModelVersion(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.repositories !== undefined && Array.isArray(attributes.repositories)) {
				for (const model of attributes.repositories) {
					if (model instanceof Models.ProjectRepositorie) {
						this.repositories.push(model);
					} else {
						this.repositories.push(new Models.ProjectRepositorie(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		botVersions {
			${Models.ProjectBotVersion.getAttributes().join('\n')}
			botVersion {
				${Models.BotVersionEntity.getAttributes().join('\n')}
			}
		}
		modelVersions {
			${Models.ProjectModelVersion.getAttributes().join('\n')}
			modelVersion {
				${Models.DiagramModelVersionEntity.getAttributes().join('\n')}
			}
		}
		repositories {
			${Models.ProjectRepositorie.getAttributes().join('\n')}
			repositorie {
				${Models.RepositoryEntity.getAttributes().join('\n')}
			}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			botVersions: {},
			modelVersions: {},
			repositories: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'botVersions',
							'modelVersions',
							'repositories',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProjectEntity.prototype, 'created');
CRUD(modifiedAttr)(ProjectEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
