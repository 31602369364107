import { OrganisationEntity } from 'Models/Entities';
import React from 'react';
import { Button, Display } from 'Views/Components/Button/Button';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { SideForm } from './Common';

interface OrganisationFormProps {
	organisation?: OrganisationEntity;
	submit: {
		action: (organisationModel: OrganisationEntity) => void;
		label: string;
	}
	onCancel?: () => void;
}

export default function OrganisationForm(props: OrganisationFormProps) {
	const { organisation, submit, onCancel } = props;

	if (!organisation) return null;

	const onSubmit = async (e: React.FormEvent<Element>) => {
		e.preventDefault();
		submit.action(organisation);
	};

	return (
		<SideForm onSubmit={onSubmit}>
			<TextField
				model={organisation}
				modelProperty="name"
				label="Organisation name"
			/>
			<ButtonGroup>
				<Button display={Display.Solid} type="submit">{submit.label}</Button>
				{onCancel && <Button onClick={onCancel}>Cancel</Button>}
			</ButtonGroup>
		</SideForm>
	);
}
