import { IOrganisationEntityAttributes } from 'Models/Entities/OrganisationEntity';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BillingService from 'Services/Api/BillingService';
import { WideFirstColumnTable } from './Common';

interface OrganisationListProps {
	organisations?: IOrganisationEntityAttributes[];
}

export default function OrganisationList(props: OrganisationListProps) {
	const { organisations } = props;

	const history = useHistory();

	const navigateToOrganisation = (orgId?: string) => {
		if (!orgId) return;
		history.push(`/admin/billing/organisation/${orgId}`);
	};

	if (!organisations) {
		return <div>Loading...</div>;
	}

	return (
		<WideFirstColumnTable clickableRows>
			<thead>
				<tr>
					<th>Name</th>
					<th>Created</th>
				</tr>
			</thead>
			<tbody>
				{
					organisations.map(organisation => (
						<tr key={organisation.id} onClick={() => navigateToOrganisation(organisation.id)}>
							<td>{organisation.name}</td>
							<td>{moment(organisation.created).format('LLL')}</td>
						</tr>
					))
				}
			</tbody>
		</WideFirstColumnTable>
	);
}
