import { IOrganisationEntityAttributes, IUserEntityAttributes } from 'Models/Entities';
import OrganisationEntity from 'Models/Entities/OrganisationEntity';
import UserEntity from 'Models/Entities/UserEntity';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BillingService, { AuthUserInput } from 'Services/Api/BillingService';
import styled from 'styled-components';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import BillingPage from './BillingPage';
import OrganisationForm from './OrganisationForm';
import OrganisationList from './OrganisationList';
import UserForm from './UserForm';
import UserList from './UserList';

const newUserEntity = {
	fullName: '',
	email: '',
	password: '',
};

const newOrganisationEntity = {
	name: '',
};

export default function OrgsAndUsers() {
	const { tab } = useParams<{ tab: 'organisations' | 'users' }>();

	const history = useHistory();

	const navigateTo = (path: string) => {
		history.push(`/admin/billing/${path}`);
	};

	const tabName = tab.charAt(0).toUpperCase() + tab.slice(1);

	const [newOrganisation, setNewOrganisation] = useState<OrganisationEntity>();
	const [newUser, setNewUser] = useState<AuthUserInput>();

	const [searchString, setSearchString] = useState<string>('');

	useEffect(() => {
		setNewOrganisation(new OrganisationEntity(newOrganisationEntity));
		setNewUser(new AuthUserInput({ ...newUserEntity }));
	}, []);

	const [users, setUsers] = useState<IUserEntityAttributes[]>();
	const [organisations, setOrganisations] = useState<IOrganisationEntityAttributes[]>();

	useEffect(() => {
		if (tab === 'users') {
			BillingService.getUsers().then(response => setUsers(response.data));
		} else if (tab === 'organisations') {
			BillingService.getOrganisations().then(response => setOrganisations(response.data));
		}
	}, [tab]);

	let leftContent = null;
	let rightContent = null;
	let filteredUsers = users;
	let filteredOrganisations = organisations;
	const normalisedSearchString = searchString.toLowerCase().trim();
	switch (tab) {
		case 'organisations':
			if (normalisedSearchString) {
				filteredOrganisations = organisations?.filter(
					org => org.name.toLowerCase().includes(normalisedSearchString),
				);
			}
			leftContent = <OrganisationList organisations={filteredOrganisations} />;
			rightContent = (
				<OrganisationForm
					organisation={newOrganisation}
					submit={{
						label: 'Add organisation',
						action: async organisation => {
							await organisation.save();
							history.push(`/admin/billing/organisation/${organisation.id}`);
						},
					}}
				/>
			);
			break;
		case 'users':
			if (normalisedSearchString) {
				filteredUsers = users?.filter(
					user => user.name.toLowerCase().includes(normalisedSearchString)
					|| user.email.toLowerCase().includes(normalisedSearchString),
				);
			}
			leftContent = <UserList users={filteredUsers} />;
			rightContent = (
				<UserForm
					user={newUser}
					submit={{
						label: 'Add user',
						action: async user => {
							BillingService.createUser(user).then(res => {
								setUsers([...users ?? [], new UserEntity({
									email: res.email,
									created: new Date(res.created),
									modified: new Date(res.modified),
									id: res.id,
									name: `${res.givenName} ${res.familyName}`,
								})]);
								setNewUser(new AuthUserInput({ ...newUserEntity }));
							});
						},
					}}
				/>
			);
			break;
	}

	return (
		<BillingPage crumbs={[{ name: tabName }]}>
			<Content>
				<div className="left-column">
					<Horizontal>
						<StyledTabs>
							<Button
								display={Display.Solid}
								colors={tab === 'organisations' ? Colors.Primary : Colors.Secondary}
								onClick={() => navigateTo('organisations')}
							>
								Orgs
							</Button>
							<Button
								display={Display.Solid}
								colors={tab === 'users' ? Colors.Primary : Colors.Secondary}
								onClick={() => navigateTo('users')}
							>
								Users
							</Button>
						</StyledTabs>
						<SearchInput
							type="text"
							placeholder="Search"
							value={searchString}
							onChange={e => setSearchString(e.target.value)}
						/>
					</Horizontal>
					{leftContent}
				</div>
				<div>
					<Header>
						<h3>New {tab.slice(0, -1)}</h3>
					</Header>
					{rightContent}
				</div>
			</Content>
		</BillingPage>
	);
}

const Content = styled.div`
	display: flex;

	.left-column {
		flex-grow: 1;
		margin-right: 2rem;
	}
`;

const StyledTabs = styled.div`
	display: flex;
	margin-bottom: 1rem;

	.new-button {
		margin-left: auto;
	}
`;

const Header = styled.div`
	display: flex;
	margin-bottom: 1rem;

	h3 {
		margin: 0;
	}

	.new-btn {
		margin-left: auto;
	}
`;

const Horizontal = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SearchInput = styled.input`
	width: 300px !important;
	padding: 1rem;
`;
