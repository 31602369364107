import OrganisationEntity from 'Models/Entities/OrganisationEntity';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'Views/Components/Button/Button';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { TextField } from 'Views/Components/TextBox/TextBox';

export default function NewOrgForm() {
	const [newOrganisation, setNewOrganisation] = useState<OrganisationEntity>();

	useEffect(() => {
		setNewOrganisation(new OrganisationEntity());
	}, []);

	const history = useHistory();

	const onSubmit = async (e: React.FormEvent<Element>) => {
		e.preventDefault();
		await newOrganisation?.save();
		history.push(`/admin/billing/organisation/${newOrganisation?.id}`);
	};

	if (!newOrganisation) return null;

	return (
		<form onSubmit={onSubmit}>
			<TextField
				model={newOrganisation}
				modelProperty="name"
				label="Organisation name"
			/>
			<ButtonGroup>
				<Button type="submit">Submit</Button>
				<Button onClick={() => history.push('/admin/billing/organisations')}>Cancel</Button>
			</ButtonGroup>
		</form>
	);
}
