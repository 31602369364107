/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsPipelineVersionEntity from 'Models/Security/Acl/VisitorsPipelineVersionEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPipelineVersionEntityAttributes extends IModelAttributes {
	isPublished: boolean;
	additionalGradleDependencies: string;
	isWorkingVersion: boolean;
	version: string;
	sourceDiagram: string;

	diagramModelVersions: Array<
		| Models.PipelineVersionDiagramModelVersion
		| Models.IPipelineVersionDiagramModelVersionAttributes
	>;
	metamodelVersions: Array<
		| Models.PipelineVersionMetamodelVersion
		| Models.IPipelineVersionMetamodelVersionAttributes
	>;
	pipelineRuns: Array<
		| Models.PipelineRunEntity
		| Models.IPipelineRunEntityAttributes
	>;
	botVersions: Array<
		| Models.BotVersionPipelineVersion
		| Models.IBotVersionPipelineVersionAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	pipelineId: string;
	pipeline: Models.PipelineEntity | Models.IPipelineEntityAttributes;
	repositories: Array<
		| Models.RepositoriePipelineVersion
		| Models.IRepositoriePipelineVersionAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PipelineVersionEntity', 'Pipeline Version')
// % protected region % [Customise your entity metadata here] end
export default class PipelineVersionEntity extends Model
	implements IPipelineVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPipelineVersionEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] off begin
	@observable
	@attribute<PipelineVersionEntity, boolean>()
	@CRUD({
		name: 'Is Published',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Additional Gradle Dependencies'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<PipelineVersionEntity, string>()
	@CRUD({
		name: 'Additional Gradle Dependencies',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public additionalGradleDependencies: string;
	// % protected region % [Modify props to the crud options here for attribute 'Additional Gradle Dependencies'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] off begin
	@observable
	@attribute<PipelineVersionEntity, boolean>()
	@CRUD({
		name: 'Is Working Version',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isWorkingVersion: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute<PipelineVersionEntity, string>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] on begin
	/**
	 * JSON
	 */
	@observable
	@attribute<PipelineVersionEntity, string>()
	@CRUD({
		name: 'Source Diagram',
		displayType: 'textarea',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sourceDiagram: string;
	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] end

	/**
	 * The hardcoded models used by the pipeline
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] off begin
		name: 'Diagram Model Version',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PipelineVersionDiagramModelVersion,
		optionEqualFunc: makeJoinEqualsFunc('diagramModelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'pipelineVersionEntity',
			oppositeEntityName: 'diagramModelVersionEntity',
			relationName: 'pipelineVersion',
			relationOppositeName: 'diagramModelVersion',
			entity: () => Models.PipelineVersionEntity,
			joinEntity: () => Models.PipelineVersionDiagramModelVersion,
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] end
	})
	public diagramModelVersions: Models.PipelineVersionDiagramModelVersion[] = [];

	/**
	 * The hardcoded metamodels used by the pipeline
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Version',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PipelineVersionMetamodelVersion,
		optionEqualFunc: makeJoinEqualsFunc('metamodelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'pipelineVersionEntity',
			oppositeEntityName: 'metamodelVersionEntity',
			relationName: 'pipelineVersion',
			relationOppositeName: 'metamodelVersion',
			entity: () => Models.PipelineVersionEntity,
			joinEntity: () => Models.PipelineVersionMetamodelVersion,
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersions: Models.PipelineVersionMetamodelVersion[] = [];

	/**
	 * [CASCADE] The details of each instance of this pipeline version being executed
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] off begin
		name: 'Pipeline Runs',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.PipelineRunEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineRuns',
			oppositeEntity: () => Models.PipelineRunEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] end
	})
	public pipelineRuns: Models.PipelineRunEntity[] = [];

	/**
	 * Bot versions associated with pipeline versions
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] off begin
		name: 'Bot Version',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BotVersionPipelineVersion,
		optionEqualFunc: makeJoinEqualsFunc('botVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'pipelineVersionEntity',
			oppositeEntityName: 'botVersionEntity',
			relationName: 'pipelineVersion',
			relationOppositeName: 'botVersion',
			entity: () => Models.PipelineVersionEntity,
			joinEntity: () => Models.BotVersionPipelineVersion,
			oppositeEntity: () => Models.BotVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] end
	})
	public botVersions: Models.BotVersionPipelineVersion[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * [CASCADE] Version of a pipeline
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] on begin
		name: 'Pipeline',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 60,
		referenceTypeFunc: () => Models.PipelineEntity,
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] end
	})
	public pipelineId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public pipeline: Models.PipelineEntity;

	/**
	 * The hardcoded repositories used by the pipeline
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] off begin
		name: 'Repositorie',
		displayType: 'reference-multicombobox',
		order: 120,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RepositoriePipelineVersion,
		optionEqualFunc: makeJoinEqualsFunc('repositorieId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'pipelineVersionEntity',
			oppositeEntityName: 'repositoryEntity',
			relationName: 'pipelineVersion',
			relationOppositeName: 'repositorie',
			entity: () => Models.PipelineVersionEntity,
			joinEntity: () => Models.RepositoriePipelineVersion,
			oppositeEntity: () => Models.RepositoryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Repositorie'] end
	})
	public repositories: Models.RepositoriePipelineVersion[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPipelineVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPipelineVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isPublished !== undefined) {
				this.isPublished = attributes.isPublished;
			}
			if (attributes.additionalGradleDependencies !== undefined) {
				this.additionalGradleDependencies = attributes.additionalGradleDependencies;
			}
			if (attributes.isWorkingVersion !== undefined) {
				this.isWorkingVersion = attributes.isWorkingVersion;
			}
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.sourceDiagram !== undefined) {
				this.sourceDiagram = attributes.sourceDiagram;
			}
			if (attributes.diagramModelVersions !== undefined && Array.isArray(attributes.diagramModelVersions)) {
				for (const model of attributes.diagramModelVersions) {
					if (model instanceof Models.PipelineVersionDiagramModelVersion) {
						this.diagramModelVersions.push(model);
					} else {
						this.diagramModelVersions.push(new Models.PipelineVersionDiagramModelVersion(model));
					}
				}
			}
			if (attributes.metamodelVersions !== undefined && Array.isArray(attributes.metamodelVersions)) {
				for (const model of attributes.metamodelVersions) {
					if (model instanceof Models.PipelineVersionMetamodelVersion) {
						this.metamodelVersions.push(model);
					} else {
						this.metamodelVersions.push(new Models.PipelineVersionMetamodelVersion(model));
					}
				}
			}
			if (attributes.pipelineRuns !== undefined && Array.isArray(attributes.pipelineRuns)) {
				for (const model of attributes.pipelineRuns) {
					if (model instanceof Models.PipelineRunEntity) {
						this.pipelineRuns.push(model);
					} else {
						this.pipelineRuns.push(new Models.PipelineRunEntity(model));
					}
				}
			}
			if (attributes.botVersions !== undefined && Array.isArray(attributes.botVersions)) {
				for (const model of attributes.botVersions) {
					if (model instanceof Models.BotVersionPipelineVersion) {
						this.botVersions.push(model);
					} else {
						this.botVersions.push(new Models.BotVersionPipelineVersion(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.pipelineId !== undefined) {
				this.pipelineId = attributes.pipelineId;
			}
			if (attributes.pipeline !== undefined) {
				if (attributes.pipeline === null) {
					this.pipeline = attributes.pipeline;
				} else if (attributes.pipeline instanceof Models.PipelineEntity) {
					this.pipeline = attributes.pipeline;
					this.pipelineId = attributes.pipeline.id;
				} else {
					this.pipeline = new Models.PipelineEntity(attributes.pipeline);
					this.pipelineId = this.pipeline.id;
				}
			}
			if (attributes.repositories !== undefined && Array.isArray(attributes.repositories)) {
				for (const model of attributes.repositories) {
					if (model instanceof Models.RepositoriePipelineVersion) {
						this.repositories.push(model);
					} else {
						this.repositories.push(new Models.RepositoriePipelineVersion(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		diagramModelVersions {
			${Models.PipelineVersionDiagramModelVersion.getAttributes().join('\n')}
			diagramModelVersion {
				${Models.DiagramModelVersionEntity.getAttributes().join('\n')}
			}
		}
		metamodelVersions {
			${Models.PipelineVersionMetamodelVersion.getAttributes().join('\n')}
			metamodelVersion {
				${Models.MetamodelVersionEntity.getAttributes().join('\n')}
			}
		}
		botVersions {
			${Models.BotVersionPipelineVersion.getAttributes().join('\n')}
			botVersion {
				${Models.BotVersionEntity.getAttributes().join('\n')}
			}
		}
		repositories {
			${Models.RepositoriePipelineVersion.getAttributes().join('\n')}
			repositorie {
				${Models.RepositoryEntity.getAttributes().join('\n')}
			}
		}
		pipelineRuns {
			${Models.PipelineRunEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		pipeline {
			${Models.PipelineEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			diagramModelVersions: {},
			metamodelVersions: {},
			botVersions: {},
			repositories: {},
			pipelineRuns: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'diagramModelVersions',
							'metamodelVersions',
							'botVersions',
							'repositories',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.version;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PipelineVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(PipelineVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
