/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsVisualLibraryVersionReferenceManyToMany from '../Security/Acl/VisitorsVisualLibraryVersionReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMetamodelVersionVisualLibraryVersionAttributes extends IModelAttributes {
	metamodelVersionId: string;
	visualLibraryVersionId: string;

	metamodelVersion: Models.MetamodelVersionEntity | Models.IMetamodelVersionEntityAttributes;
	visualLibraryVersion: Models.VisualLibraryVersionEntity | Models.IVisualLibraryVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('MetamodelVersionVisualLibraryVersion')
export default class MetamodelVersionVisualLibraryVersion
	extends Model
	implements IMetamodelVersionVisualLibraryVersionAttributes {
	public static acls: IAcl[] = [
		new VisitorsVisualLibraryVersionReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public metamodelVersionId: string;

	@observable
	@attribute()
	public visualLibraryVersionId: string;

	@observable
	@attribute({ isReference: true })
	public metamodelVersion: Models.MetamodelVersionEntity;

	@observable
	@attribute({ isReference: true })
	public visualLibraryVersion: Models.VisualLibraryVersionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IMetamodelVersionVisualLibraryVersionAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.metamodelVersionId) {
				this.metamodelVersionId = attributes.metamodelVersionId;
			}
			if (attributes.visualLibraryVersionId) {
				this.visualLibraryVersionId = attributes.visualLibraryVersionId;
			}

			if (attributes.metamodelVersion) {
				if (attributes.metamodelVersion instanceof Models.MetamodelVersionEntity) {
					this.metamodelVersion = attributes.metamodelVersion;
					this.metamodelVersionId = attributes.metamodelVersion.id;
				} else {
					this.metamodelVersion = new Models.MetamodelVersionEntity(attributes.metamodelVersion);
					this.metamodelVersionId = this.metamodelVersion.id;
				}
			} else if (attributes.metamodelVersionId !== undefined) {
				this.metamodelVersionId = attributes.metamodelVersionId;
			}

			if (attributes.visualLibraryVersion) {
				if (attributes.visualLibraryVersion instanceof Models.VisualLibraryVersionEntity) {
					this.visualLibraryVersion = attributes.visualLibraryVersion;
					this.visualLibraryVersionId = attributes.visualLibraryVersion.id;
				} else {
					this.visualLibraryVersion = new Models.VisualLibraryVersionEntity(attributes.visualLibraryVersion);
					this.visualLibraryVersionId = this.visualLibraryVersion.id;
				}
			} else if (attributes.visualLibraryVersionId !== undefined) {
				this.visualLibraryVersionId = attributes.visualLibraryVersionId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
