import { action, observable } from 'mobx';

class RightPanelStore {
	@observable
	public content: React.ReactNode | undefined;

	constructor() {
		this.content = undefined;
	}

	@action
	public setContent(content: React.ReactNode | undefined) {
		this.content = content;
	}
}

const rightPanelStore = new RightPanelStore();
export default rightPanelStore;
