/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable max-len */
import * as React from 'react';
import { action } from 'mobx';
import * as AdminPages from './Pages/Admin/Entity';
import Auth from './Components/Auth/Auth';
import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from './Components/Topbar/Topbar';
import PageLinks from './Pages/Admin/PageLinks';
import StyleguidePage from './Pages/Admin/StyleguidePage';
import Spinner from 'Views/Components/Spinner/Spinner';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import Billing from './Pages/Admin/Billing/Billing';
// % protected region % [Add any extra imports here] end

// % protected region % [Customize lazy imports here] off begin
const GraphiQlLazy = React.lazy(() => import('./Pages/Admin/Graphiql'));
// % protected region % [Customize lazy imports here] end

export default class Admin extends React.Component<RouteComponentProps> {
	path = this.props.match.path === '/' ? '' : this.props.match.path;

	// % protected region % [Override componentDidMount here] off begin
	public componentDidMount() {
		this.setAppLocation();
	}
	// % protected region % [Override componentDidMount here] end

	// % protected region % [Override setAppLocation here] off begin
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}
	// % protected region % [Override setAppLocation here] end

	private adminSwitch = () => {
		// % protected region % [Override access control logic here] off begin
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Redirect to="/404" />;
		}
		// % protected region % [Override access control logic here] end

		return (
			<>
				{
				// % protected region % [Override contents here] off begin
				}
				<PageLinks {...this.props} />
				{
				// % protected region % [Override contents here] end
				}
				<div className="body-content">
					<Switch>
						{/* These routes require a login to view */}

						{/* Admin entity pages */}
						<Route exact path={`${this.path}`} component={AdminPage} />
						<Route path={`${this.path}/User`} component={AllUsersPage} />
						<Route path={`${this.path}/AccessTokenEntity`} component={AdminPages.AccessTokenEntityPage} />
						<Route path={`${this.path}/BotEntity`} component={AdminPages.BotEntityPage} />
						<Route path={`${this.path}/BotVersionEntity`} component={AdminPages.BotVersionEntityPage} />
						<Route path={`${this.path}/DiagramCheckpointEntity`} component={AdminPages.DiagramCheckpointEntityPage} />
						<Route path={`${this.path}/DiagramEntity`} component={AdminPages.DiagramEntityPage} />
						<Route path={`${this.path}/DiagramModelEntity`} component={AdminPages.DiagramModelEntityPage} />
						<Route path={`${this.path}/DiagramModelVersionEntity`} component={AdminPages.DiagramModelVersionEntityPage} />
						<Route path={`${this.path}/DiagramNodeEntity`} component={AdminPages.DiagramNodeEntityPage} />
						<Route path={`${this.path}/MetamodelEntity`} component={AdminPages.MetamodelEntityPage} />
						<Route path={`${this.path}/MetamodelVersionEntity`} component={AdminPages.MetamodelVersionEntityPage} />
						<Route path={`${this.path}/OrganisationEntity`} component={AdminPages.OrganisationEntityPage} />
						<Route path={`${this.path}/OrganisationUserEntity`} component={AdminPages.OrganisationUserEntityPage} />
						<Route path={`${this.path}/PipelineEntity`} component={AdminPages.PipelineEntityPage} />
						<Route path={`${this.path}/PipelineRunEntity`} component={AdminPages.PipelineRunEntityPage} />
						<Route path={`${this.path}/PipelineStepRunEntity`} component={AdminPages.PipelineStepRunEntityPage} />
						<Route path={`${this.path}/PipelineVersionEntity`} component={AdminPages.PipelineVersionEntityPage} />
						<Route path={`${this.path}/ProjectEntity`} component={AdminPages.ProjectEntityPage} />
						<Route path={`${this.path}/RepositoryCredentialsEntity`} component={AdminPages.RepositoryCredentialsEntityPage} />
						<Route path={`${this.path}/RepositoryEntity`} component={AdminPages.RepositoryEntityPage} />
						<Route path={`${this.path}/RunnerEntity`} component={AdminPages.RunnerEntityPage} />
						<Route path={`${this.path}/SnippetEntity`} component={AdminPages.SnippetEntityPage} />
						<Route path={`${this.path}/UserEntity`} component={AdminPages.UserEntityPage} />
						<Route path={`${this.path}/VisualLibraryEntity`} component={AdminPages.VisualLibraryEntityPage} />
						<Route path={`${this.path}/VisualLibraryVersionEntity`} component={AdminPages.VisualLibraryVersionEntityPage} />

						{
						// % protected region % [Add any extra page routes here] on begin
						}
						<Route path={`${this.path}/billing`} component={Billing} />
						{
						// % protected region % [Add any extra page routes here] end
						}
					</Switch>
				</div>
				{
				// % protected region % [Add any admin footer content here] off begin
				}
				{
				// % protected region % [Add any admin footer content here] end
				}
			</>
		);
	}

	public render() {
		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] off begin
					}
					<Topbar currentLocation="admin" />
					{
					// % protected region % [Modify Topbar] end
					}
					<Switch>
						<Route path={`${this.path}/styleguide`}>
							<Auth {...this.props}>
								<div className="admin">
									<PageLinks {...this.props} />
								</div>
								<div className="frontend">
									<div className="body-content">
										<StyleguidePage {...this.props} />
									</div>
								</div>
							</Auth>
						</Route>
						<Route>
							<div className="admin">
								<Auth {...this.props}>
									<Switch>
										{
										// % protected region % [Modify top level admin routing here] off begin
										}
										<Route path={`${this.path}/graphiql`}>
											<React.Suspense fallback={<Spinner />}>
												<GraphiQlLazy />
											</React.Suspense>
										</Route>
										<Route component={this.adminSwitch} />
										{
										// % protected region % [Modify top level admin routing here] end
										}
									</Switch>
								</Auth>
							</div>
						</Route>
					</Switch>
				</div>
			</>
		);
	}
}
