import React, { useEffect } from 'react';
import {
	Redirect, Route, RouteComponentProps, Switch,
} from 'react-router';
import styled from 'styled-components';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import AddNew from './AddNew';
import OrgsAndUsers from './OrgsAndUsers';
import RightPanel from './RightPanel';
import rightPanelStore from './RightPanelStore';
import ViewOrganisation from './ViewOrganisation';
import ViewUser from './ViewUser';

export default function Billing(props: RouteComponentProps) {
	const { match } = props;
	useEffect(() => {
		const onClick = (e: MouseEvent) => {
			rightPanelStore.setContent(null);
		};
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	}, []);

	return (
		<SecuredPage groups={['Super Administrators', '']}>
			<PageWrapper {...props}>
				<ContentWrapper>
					<Switch>
						<Route
							exact
							path={`${match.path}`}
							render={() => <Redirect to={`${match.path}/organisations`} />}
						/>
						<Route path={`${match.path}/organisation/:orgId`} component={ViewOrganisation} />
						<Route path={`${match.path}/user/:userId/`} component={ViewUser} />
						<Route path={`${match.path}/:tab/new`} component={AddNew} />
						<Route path={`${match.path}/:tab`} component={OrgsAndUsers} />
					</Switch>
					<RightPanel />
				</ContentWrapper>
			</PageWrapper>
		</SecuredPage>
	);
}

const ContentWrapper = styled.div`
	padding: 2rem;
`;
