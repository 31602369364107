/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { QueryResult } from '@apollo/client';
import Collection, {
	expandFn,
	ICollectionActionProps,
	ICollectionBulkActionProps,
	ICollectionItemActionProps,
	ICollectionProps,
	showExpandFn,
} from '../Collection/Collection';
import { Button, Display } from '../Button/Button';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { IModelType, Model } from 'Models/Model';
import {
	exportAll,
	getAttributeCRUDOptions,
	getModelDisplayName,
	getModelName,
} from 'Util/EntityUtils';
import { action, computed, observable } from 'mobx';
import Spinner from '../Spinner/Spinner';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import ModelQuery, {
	HasCondition,
	IOrderByCondition,
	IWhereCondition,
	ModelQueryChildren,
	IWhereConditionApi,
} from '../ModelCollection/ModelQuery';
import type { ICollectionFilterPanelProps } from '../Collection/CollectionFilterPanel';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { lowerCaseFirst } from 'Util/StringUtils';
import SecurityService from 'Services/SecurityService';
import * as _ from 'lodash';
import classNames from 'classnames';
import { confirmModal } from '../Modal/ModalUtils';
import alert from 'Util/ToastifyUtils';
import { IEntityContextMenuActions } from '../EntityContextMenu/EntityContextMenu';
import moment from 'moment';
import { AttributeCRUDOptions } from 'Models/CRUDOptions';
import { convertCaseComparisonToPascalCase } from 'Util/GraphQLUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type refetchFn = (variables?: any) => Promise<any>;
export type actionOverrideFn<T extends Model> = (
	refetchFn: viewActionOptions<T>,
) => ICollectionItemActionProps<T> | undefined;
export type viewActionOptions<T extends Model> = {
	refetch: refetchFn,
	self: EntityCollection<T>,
}

export interface AdditionalBulkActions<T> extends ICollectionActionProps<T> {
	bulkAction: (
		mode: 'includedIds' | 'excludedIds',
		ids: string[],
		event: React.MouseEvent<Element, MouseEvent>,
	) => void;
	// % protected region % [Add any extra AdditionalBulkActions fields here] off begin
	// % protected region % [Add any extra AdditionalBulkActions fields here] end
}

export interface IEntityCollectionProps<T extends Model> extends RouteComponentProps {
	modelType: IModelType<T>;
	expandList?: expandFn<T>;
	showExpandButton?: showExpandFn<T>;
	additionalBulkActions?: AdditionalBulkActions<T>[];
	additionalFilters?: Array<IFilter<T>>;
	perPage?: number;
	orderBy?: IOrderByCondition<T>;
	actionsMore?: IEntityContextMenuActions<T>;
	url?: string;
	additionalTableActions?: Array<ICollectionItemActionProps<T>>;
	conditions?: Array<Array<IWhereCondition<T>>>;
	has?: HasCondition<T>[][];
	removeCreatedFilter?: boolean;
	removeModifiedFilter?: boolean;
	filterOrientationRow?: boolean;
	createAction?: (refetchFn: viewActionOptions<T>) => React.ReactNode;
	viewAction?: actionOverrideFn<T>;
	deleteAction?: actionOverrideFn<T>;
	updateAction?: actionOverrideFn<T>;
	disableBulkExport?: boolean;
	disableBulkDelete?: boolean;
	collectionProps?: Partial<ICollectionProps<T>>;
	disableCreateButtonSecurity?: boolean;
	disableReadButtonSecurity?: boolean;
	disableUpdateButtonSecurity?: boolean;
	disableDeleteButtonSecurity?: boolean;
	headerAttributes?: AttributeCRUDOptions[];
	additionalActions?: React.ReactNode[];
	/** Function to mutate the attribute options before it is rendered */
	mutateOptions?: (
		model: Model | Model[],
		options: AttributeCRUDOptions[],
		formMode: EntityFormMode
	) => AttributeCRUDOptions[];
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

export interface ISearch {
	searchTerm: string;
	// % protected region % [Add any extra ISearch fields here] off begin
	// % protected region % [Add any extra ISearch fields here] end
}

@observer
class EntityCollection<T extends Model> extends React.Component<IEntityCollectionProps<T>, any> {
	public refetch: refetchFn = () => Promise.resolve();

	@observable
	public search: ISearch = { searchTerm: '' };

	@observable
	public filterConfig: ICollectionFilterPanelProps<T>;

	@observable
	public filterApplied: boolean = false;

	@observable
	public orderBy: IOrderByCondition<T> | undefined;

	// % protected region % [Customize _orderBy method here] off begin
	@computed
	public get _orderBy() {
		if (this.orderBy === undefined) {
			// set the default order by to display the options in reverse creation order
			return { path: 'Created', descending: true };
		}
		return this.orderBy;
	}
	// % protected region % [Customize _orderBy method here] end

	@observable
	public allSelectedItemIds: string[] = new Array<string>();

	@observable
	public allExcludedItemIds: string[] = new Array<string>();

	@observable
	public allPagesSelected: boolean = false;

	@observable
	public pageNo: number = 0;

	@observable
	public has?: HasCondition<T>[][];

	@computed
	public get security() {
		const {
			modelType,
			disableCreateButtonSecurity,
			disableReadButtonSecurity,
			disableUpdateButtonSecurity,
			disableDeleteButtonSecurity,
		} = this.props;

		return {
			create: disableCreateButtonSecurity ? true : SecurityService.canCreate(modelType),
			read: disableReadButtonSecurity ? true : SecurityService.canRead(modelType),
			update: disableUpdateButtonSecurity ? true : SecurityService.canUpdate(modelType),
			delete: disableDeleteButtonSecurity ? true : SecurityService.canDelete(modelType),
		};
	}

	// % protected region % [Customize collectionFilters method here] off begin
	@computed
	public get collectionFilters() {
		const {
			modelType: ModelType,
			conditions: conditionsProp = [],
		} = this.props;

		let conditions: IWhereCondition<Model>[][] = [];
		const searchConditions = this.getSearchConditions();
		if (searchConditions) {
			conditions = [...searchConditions];
		}
		let filterConditions: IWhereCondition<Model>[][] | undefined;

		if (this.filterApplied) {
			filterConditions = new ModelType().getFilterConditions(this.filterConfig);
		}

		if (conditionsProp.length > 0) {
			conditions = [...conditions, ...conditionsProp]
				.map(andCondition => andCondition.map(orCondition => ({
					...orCondition,
					value: Array.isArray(orCondition.value) ? orCondition.value : [orCondition.value],
				})));
		}

		if (filterConditions && !!filterConditions.length) {
			conditions = [...conditions, ...filterConditions]
				.map(andCondition => andCondition.map(orCondition => ({
					...orCondition,
					value: _.isArray(orCondition.value) ? orCondition.value : [orCondition.value],
				})));
		}

		return conditions;
	}
	// % protected region % [Customize collectionFilters method here] end

	// % protected region % [Customize perPage method here] off begin
	private get perPage() {
		const { perPage } = this.props;
		return perPage ?? 10;
	}
	// % protected region % [Customize perPage method here] end

	// % protected region % [Customize url method here] off begin
	public get url() {
		const { url, match } = this.props;
		return url ?? match.url;
	}
	// % protected region % [Customize url method here] end

	public models: T[] = [];

	// % protected region % [Customize constructor method here] off begin
	constructor(props: IEntityCollectionProps<T>, context: any) {
		super(props, context);
		const { modelType } = this.props;

		this.filterConfig = {
			filters: this.getFilters(),
			onClearFilter: this.onClearFilter,
			onApplyFilter: this.onApplyFilter,
			onFilterChanged: this.onFilterChanged,
		};
		const defaultOrderBy = modelType.getOrderByField ? modelType.getOrderByField() : undefined;
		this.orderBy = defaultOrderBy ?? props.orderBy;
	}
	// % protected region % [Customize constructor method here] end

	// % protected region % [Customize render method here] off begin
	public render() {
		const { modelType } = this.props;
		return (
			<>
				<ModelQuery
					model={modelType}
					page={this.pageNo}
					perPage={this.perPage}
					orderBy={this._orderBy}
					conditions={this.collectionFilters}
					has={this.has}
					useListExpands
				>
					{this.renderCollection}
				</ModelQuery>
			</>
		);
	}
	// % protected region % [Customize render method here] end

	// % protected region % [Customize renderCollection method here] off begin
	protected renderCollection: ModelQueryChildren<T> = result => {
		const {
			loading, error, data, refetch,
		} = result;

		this.refetch = refetch;

		if (error) {
			return (
				<div>
					<h2>An unexpected error occurred:</h2>
					{JSON.stringify(error.message)}
				</div>
			);
		}

		const { modelType: ModelType } = this.props;
		const modelName = getModelName(ModelType);

		this.models = [];
		const dataModelName = lowerCaseFirst(`${modelName}s`);
		if (data?.[dataModelName]) {
			this.models = data[dataModelName].map((e: any) => new ModelType(e));
		}

		const tableHeaders = this.getHeaders();
		const tableActions = this.getTableActions(refetch);

		const countName = `count${modelName}s`;
		let totalRecords = 0;
		if (data?.[countName]) {
			// eslint-disable-next-line dot-notation
			totalRecords = data[countName]['number'];
		}

		const additionalActions: React.ReactNode[] = this.props.additionalActions !== undefined
			? [...this.props.additionalActions]
			: [];
		if (this.security.create) {
			additionalActions.push(this.renderCreateButton(refetch));
		}

		const menuCountFunction = () => {
			if (this.allPagesSelected) {
				return totalRecords - this.allExcludedItemIds.length;
			}

			return this.allSelectedItemIds.length;
		};

		const selectedBulkActions: Array<ICollectionBulkActionProps<T>> = [];
		if (SecurityService.canRead(ModelType) && this.props.disableBulkExport !== true) {
			selectedBulkActions.push({
				bulkAction: this.exportItems,
				label: 'Export',
				showIcon: true,
				icon: 'export',
				iconPos: 'icon-left',
			});
		}

		if (SecurityService.canDelete(ModelType) && this.props.disableBulkDelete !== true) {
			selectedBulkActions.push({
				bulkAction: () => {
					confirmModal(
						'Please confirm',
						'Are you sure you want to delete all the selected items?',
					).then(() => {
						let idsToDelete: Array<string> | undefined;
						let conditions: Array<Array<IWhereCondition<Model>>> | undefined;
						if (this.allPagesSelected) {
							conditions = this.collectionFilters as Array<Array<IWhereCondition<Model>>>;
							if (!conditions) {
								conditions = [];
							}
							const idsCondition = new Array<IWhereCondition<Model>>();
							idsCondition.push({ path: 'id', comparison: 'notIn', value: this.allExcludedItemIds });
							(conditions as Array<Array<IWhereCondition<Model>>>).push(idsCondition);
							idsToDelete = undefined;
						} else {
							idsToDelete = this.allSelectedItemIds;
							conditions = this.collectionFilters as Array<Array<IWhereCondition<Model>>>;
						}
						new ModelType().deleteWhere(conditions, idsToDelete).then(deleteResult => {
							// eslint-disable-next-line dot-notation
							if (!!deleteResult && deleteResult['value'] === true) {
								refetch();
								this.cancelAllSelection();
								alert('All selected items are deleted successfully', 'success');
							}
						}).catch(errorMessage => {
							alert(
								<div className="delete-error">
									<p className="user-error">
										These records could not be deleted because of an association
									</p>
									<p className="internal-error-title">Message:</p>
									<p className="internal-error">{errorMessage}</p>
								</div>,
								'error',
							);
						});
					});
				},
				label: 'Delete',
				showIcon: true,
				icon: 'bin-full',
				iconPos: 'icon-left',
			});
		}

		return (
			<>
				{loading && <Spinner />}
				<Collection
					selectableItems
					additionalActions={additionalActions}
					headers={tableHeaders}
					actions={tableActions}
					actionsMore={this.props.actionsMore}
					selectedBulkActions={selectedBulkActions.concat(this.props.additionalBulkActions
						? this.props.additionalBulkActions.map(ba => this.mapBulkAction(ba))
						: [])}
					onSearchTriggered={this.onSearchTriggered}
					menuFilterConfig={this.filterConfig}
					collection={this.models}
					totalRecords={totalRecords}
					perPage={this.perPage}
					pageNo={this.pageNo}
					onPageChange={this.onPageChange}
					itemSelectionChanged={this.itemSelectionChanged}
					cancelAllSelection={this.cancelAllSelection}
					menuCountFunction={menuCountFunction}
					expandList={this.props.expandList}
					showExpandButton={this.props.showExpandButton}
					getSelectedItems={this.getSelectedItems}
					onCheckedAllPages={this.onCheckedAllPages}
					idColumn="id"
					dataFields={row => ({
						created: moment(row.created).format('YYYY-MM-DD'),
						modified: moment(row.modified).format('YYYY-MM-DD'),
					})}
					orderBy={this.orderBy}
					filterOrientationRow={this.props.filterOrientationRow}
					{...this.props.collectionProps}
				/>
			</>
		);
	}
	// % protected region % [Customize renderCollection method here] end

	// % protected region % [Customize getSelectedItems method here] off begin
	public getSelectedItems = () => {
		return this.models.filter(model => {
			if (this.allPagesSelected) {
				return !this.allExcludedItemIds.some(id => {
					return model.id === id;
				});
			}
			return this.allSelectedItemIds.some(id => {
				return model.id === id;
			});
		});
	};
	// % protected region % [Customize getSelectedItems method here] end

	// % protected region % [Customize onCheckedAllPages method here] off begin
	@action
	public onCheckedAllPages = (checked: boolean) => {
		this.allPagesSelected = checked;

		if (checked) {
			this.allExcludedItemIds = [];
			const changedIds = this.models.map(item => item.id);
			if (checked) {
				this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
			} else {
				this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
			}
			const selectedItems = (new Array<T>());
			selectedItems.push(...this.models);
			return selectedItems;
		}

		this.allSelectedItemIds = [];
		const changedIds = this.models.map(item => item.id);
		if (!checked) {
			this.allExcludedItemIds = _.union(this.allSelectedItemIds, changedIds);
		} else {
			this.allExcludedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
		}

		return [];
	}
	// % protected region % [Customize onCheckedAllPages method here] end

	// % protected region % [Customize itemSelectionChanged method here] off begin
	@action
	public itemSelectionChanged = (checked: boolean, changedItems: Model[]) => {
		const changedIds = changedItems.map(item => item.id);
		if (this.allPagesSelected) {
			if (!checked) {
				this.allExcludedItemIds = _.union(this.allExcludedItemIds, changedIds);
			} else {
				this.allExcludedItemIds = _.pull(this.allExcludedItemIds, ...changedIds);
			}
		} else if (checked) {
			this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
		} else {
			this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
		}
	}
	// % protected region % [Customize itemSelectionChanged method here] end

	// % protected region % [Customize renderCreateButton method here] off begin
	public renderCreateButton(refetch: refetchFn): React.ReactNode {
		const { modelType, createAction, history } = this.props;

		if (createAction) {
			return createAction({ refetch: refetch, self: this });
		}

		const modelDisplayName = getModelDisplayName(modelType);
		return (
			<Button
				key="create"
				className={classNames(Display.Solid)}
				icon={{ icon: 'create', iconPos: 'icon-right' }}
				buttonProps={{ onClick: () => { history.push(`${this.url}/create`); } }}
			>
				Create {modelDisplayName}
			</Button>
		);
	}
	// % protected region % [Customize renderCreateButton method here] end

	// % protected region % [Customize GetHeaders method here] on begin
	public getHeaders = (): Array<ICollectionHeaderProps<T>> => {
		let attributeOptions: AttributeCRUDOptions[];
		if (this.props.headerAttributes) {
			attributeOptions = this.props.headerAttributes;
		} else {
			attributeOptions = getAttributeCRUDOptions(this.props.modelType);
			if (this.props.mutateOptions) {
				attributeOptions = this.props.mutateOptions(this.models, attributeOptions, EntityFormMode.VIEW);
			}
		}

		return attributeOptions.filter(attributeOption => attributeOption.headerColumn)
			.sort((a, b) => (a.order ?? -1) < (b.order ?? -1) ? -1 : 1)
			.map(attributeOption => {
				const headers: ICollectionHeaderProps<T> = {
					name: attributeOption.attributeName,
					displayName: attributeOption.displayName,
					sortable: true,
					sortClicked: action(() => {
						if (this.orderBy && this.orderBy.path === attributeOption.attributeName) {
							if (this.orderBy.descending) {
								const descending = !this.orderBy.descending;
								this.orderBy = { path: attributeOption.attributeName, descending };
							} else if (!this.orderBy.descending) {
								this.orderBy = undefined;
							}
							return this.orderBy;
						}

						this.orderBy = { path: attributeOption.attributeName, descending: true };
						return this.orderBy;
					}),
				};
				if (attributeOption.displayFunction) {
					headers.transformItem = item => {
						if (attributeOption.displayFunction) {
							return attributeOption.displayFunction(item[attributeOption.attributeName], item);
						}
						return item[attributeOption.name];
					};
				}
				return headers;
			});
	}
	// % protected region % [Customize GetHeaders method here] end

	// % protected region % [Customize getFilters method here] off begin
	public getFilters = (): Array<IFilter<T>> => {
		const { additionalFilters, removeCreatedFilter, removeModifiedFilter } = this.props;
		let filters = new Array<IFilter<T>>();

		if (!removeCreatedFilter) {
			filters.push({
				path: 'created',
				comparison: 'range',
				value1: undefined,
				value2: undefined,
				active: false,
				displayType: 'datepicker',
				displayName: 'Range of Date Created',
			} as IFilter<T>);
		}

		if (!removeModifiedFilter) {
			filters.push({
				path: 'modified',
				comparison: 'range',
				value1: undefined,
				value2: undefined,
				displayType: 'datepicker',
				displayName: 'Range of Date Last Modified',
			} as IFilter<T>);
		}

		filters = [...filters, ..._.cloneDeep(additionalFilters) || []];

		const enumFilters = this.getEnumFilters();

		filters = [...filters, ...enumFilters];

		return filters;
	};
	// % protected region % [Customize getFilters method here] end

	// % protected region % [Customise retrieving enum filters logic] off begin
	public getEnumFilters = () => {
		const attributeOptions = getAttributeCRUDOptions(this.props.modelType);
		return attributeOptions
			.filter(attributeOption => attributeOption.displayType === 'enum-combobox')
			.map(attributeOption => {
				return {
					path: attributeOption.attributeName,
					comparison: 'in',
					value1: [] as string[],
					displayName: attributeOption.displayName,
					displayType: 'enum-combobox',
					enumResolveFunction: attributeOption.enumResolveFunction,
				} as IFilter<T>;
			});
	}
	// % protected region % [Customise retrieving enum filters logic] end

	// % protected region % [Customize onClearFilter method here] off begin
	@action
	public onClearFilter = () => {
		this.filterConfig.filters = this.getFilters();
		this.filterApplied = false;
	};
	// % protected region % [Customize onClearFilter method here] end

	// % protected region % [Customize onApplyFilter method here] off begin
	@action
	public onApplyFilter = () => {
		this.filterApplied = true;
	};
	// % protected region % [Customize onApplyFilter method here] end

	// % protected region % [Customize onFilterChanged method here] off begin
	@action
	public onFilterChanged = () => {
		this.filterApplied = false;
	}
	// % protected region % [Customize onFilterChanged method here] end

	// % protected region % [Customize mapBulkAction method here] off begin
	public mapBulkAction = (bulkAction: AdditionalBulkActions<T>): ICollectionBulkActionProps<T> => {
		const rootFn = bulkAction.bulkAction;

		return {
			...bulkAction,
			bulkAction: (models, event) => {
				const mode = this.allPagesSelected ? 'excludedIds' : 'includedIds';
				if (mode === 'includedIds') {
					return rootFn(mode, this.allSelectedItemIds, event);
				}
				return rootFn(mode, this.allExcludedItemIds, event);
			},
		};
	};
	// % protected region % [Customize mapBulkAction method here] end

	// % protected region % [Customize exportItems method here] off begin
	public exportItems = () => {
		let conditions: IWhereConditionApi<Model>[][];
		if (this.allPagesSelected && this.collectionFilters) {
			conditions = [
				...this.collectionFilters.map(andCondition => andCondition.map(orCondition => {
					if (orCondition.case) {
						return {
							...orCondition,
							case: convertCaseComparisonToPascalCase(orCondition.case),
							value: [orCondition.value],
						};
					}
					return orCondition as IWhereConditionApi<Model>;
				})),
				[{
					path: 'id',
					comparison: 'notIn',
					value: this.allExcludedItemIds,
				}],
			];
		} else {
			conditions = [[
				{
					path: 'id',
					comparison: 'in',
					value: this.allSelectedItemIds,
				},
			]];
		}

		return exportAll(this.props.modelType, conditions);
	}
	// % protected region % [Customize exportItems method here] end

	public getTableActions = (refetch: refetchFn) => {
		const tableActions: Array<ICollectionItemActionProps<T>> = [];
		// % protected region % [Customise custom table actions here] off begin
		const {
			viewAction,
			updateAction,
			deleteAction,
			additionalTableActions,
			modelType: ModelType,
		} = this.props;

		const updateTableActions = (
			override: actionOverrideFn<T> | undefined,
			defaultAction: ICollectionItemActionProps<T>,
		) => {
			if (override) {
				const tableAction = override({ refetch: refetch, self: this });
				if (tableAction) {
					tableActions.push(tableAction);
				}
			} else {
				tableActions.push(defaultAction);
			}
		};

		if (this.security.read) {
			updateTableActions(viewAction, {
				action: item => {
					this.props.history.push({ pathname: `${this.url}/view/${item.id}` });
				},
				label: 'View',
				showIcon: true,
				icon: 'look',
				iconPos: 'icon-top',
			});
		}

		if (this.security.update) {
			updateTableActions(updateAction, {
				action: item => {
					this.props.history.push({ pathname: `${this.url}/edit/${item.id}` });
				},
				label: 'Edit',
				showIcon: true,
				icon: 'edit',
				iconPos: 'icon-top',
			});
		}

		if (this.security.delete) {
			updateTableActions(deleteAction, {
				action: item => {
					confirmModal('Please confirm', 'Are you sure you want to delete this item?').then(() => {
						new ModelType(item).delete().then(() => {
							refetch();
							alert('Deleted successfully', 'success');
						}).catch(errorMessage => {
							alert(
								(
									<div className="delete-error">
										<p className="user-error">
											This record could not be deleted because of an association
										</p>
										<p className="internal-error-title">Message:</p>
										<p className="internal-error">{errorMessage}</p>
									</div>
								),
								'error',
							);
						});
					});
				},
				label: 'Delete',
				showIcon: true,
				icon: 'bin-full',
				iconPos: 'icon-top',
			});
		}
		if (additionalTableActions) {
			tableActions.push(...additionalTableActions);
		}
		// % protected region % [Customise custom table actions here] end
		return tableActions;
	}

	// % protected region % [Customize onSearchTriggered method here] off begin
	@action
	public onSearchTriggered = (searchTerm: string) => {
		this.search.searchTerm = searchTerm;
	}
	// % protected region % [Customize onSearchTriggered method here] end

	// % protected region % [Customize cancelAllSelection method here] off begin
	@action
	public cancelAllSelection = () => {
		this.allPagesSelected = false;
		this.allSelectedItemIds = [];
		this.allExcludedItemIds = [];
	}
	// % protected region % [Customize cancelAllSelection method here] end

	// % protected region % [Customize getSearchConditions method here] off begin
	public getSearchConditions() {
		const { modelType: ModelType } = this.props;
		return new ModelType().getSearchConditions(this.search.searchTerm);
	}
	// % protected region % [Customize getSearchConditions method here] end

	// % protected region % [Customize onPageChange method here] off begin
	@action
	private onPageChange = (pageNo: number) => {
		this.pageNo = pageNo;
	}
	// % protected region % [Customize onPageChange method here] end

	// % protected region % [Add any extra EntityCollection fields here] off begin
	// % protected region % [Add any extra EntityCollection fields here] end
}

export default EntityCollection;
