import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import rightPanelStore from './RightPanelStore';

const TRANSITION_MS = 150;

function RightPanel() {
	const [panelContent, setPanelContent] = useState<React.ReactNode>(null);
	const [right, setRight] = useState<number>(100);

	const { content } = rightPanelStore;

	useEffect(() => {
		setRight(content ? 0 : 100);
		if (content === null) {
			const timeout = setTimeout(() => {
				setPanelContent(content);
			}, TRANSITION_MS);
			return () => clearTimeout(timeout);
		}
		setPanelContent(content);
		return () => {};
	}, [content]);

	return (
		<Content onClick={e => e.stopPropagation()} right={right}>
			<div className="container">
				{panelContent}
			</div>
		</Content>
	);
}

export default observer(RightPanel);

const Content = styled.div<{ right: number; }>`
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
    background: #ededed;
    box-shadow: 0px 0px 20px #00000030;
	transform: translateX(${({ right }) => right}%);
	transition: transform ${TRANSITION_MS}ms ease-in-out;
	overflow-y: auto;
	overflow-x: hidden;

	.container {
		position: relative;
		padding: 2rem;
		height: 100%;
	}
`;
