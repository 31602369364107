import styled from 'styled-components';

export const StyledTable = styled.table<{ clickableRows?: boolean }>`
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 2rem;
	thead tr {
		background: #f3f3f3;
	}
	tr {
		th {
			text-align: left;
			padding: 0.75rem;
			font-size: 1.2rem;
		}
		td {
			text-align: left;
			padding: 0.75rem;
		}
	}
	tbody tr {
		background: #f7f7f7;
		${({ clickableRows }) => clickableRows && `&:hover {
			background-color: #f1f1f1;
			cursor: pointer;
		}`}
	}
`;

export const WideFirstColumnTable = styled(StyledTable)`
	td:first-child {
		width: 100%;
	}
	td:not(:first-child) {
		white-space: nowrap;
	}
`;

export const NormalTable = styled(StyledTable)`
	td:not(:first-child) {
		white-space: nowrap;
	}
`;

export const SideForm = styled.form`
	min-width: 300px;
`;

export const EmptyState = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4rem;
	font-size: 1.5rem;
    color: #aaa;
    background: #fafafa;
    margin-bottom: 2rem;
`;
