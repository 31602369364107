/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDiagramModelVersionEntity from 'Models/Security/Acl/VisitorsDiagramModelVersionEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDiagramModelVersionEntityAttributes extends IModelAttributes {
	isWorkingVersion: boolean;
	isPublished: boolean;
	version: string;
	sourceDiagram: string;

	diagramModelId: string;
	diagramModel: Models.DiagramModelEntity | Models.IDiagramModelEntityAttributes;
	metamodelVersionId?: string;
	metamodelVersion?: Models.MetamodelVersionEntity | Models.IMetamodelVersionEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	pipelineVersions: Array<
		| Models.PipelineVersionDiagramModelVersion
		| Models.IPipelineVersionDiagramModelVersionAttributes
	>;
	modelVersionDependencys: Array<
		| Models.DependentModelVersionModelVersionDependency
		| Models.IDependentModelVersionModelVersionDependencyAttributes
	>;
	dependentModelVersions: Array<
		| Models.DependentModelVersionModelVersionDependency
		| Models.IDependentModelVersionModelVersionDependencyAttributes
	>;
	projects: Array<
		| Models.ProjectModelVersion
		| Models.IProjectModelVersionAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DiagramModelVersionEntity', 'Diagram Model Version')
// % protected region % [Customise your entity metadata here] end
export default class DiagramModelVersionEntity extends Model
	implements IDiagramModelVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDiagramModelVersionEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] off begin
	@observable
	@attribute<DiagramModelVersionEntity, boolean>()
	@CRUD({
		name: 'Is Working Version',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isWorkingVersion: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] off begin
	@observable
	@attribute<DiagramModelVersionEntity, boolean>()
	@CRUD({
		name: 'Is Published',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute<DiagramModelVersionEntity, string>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<DiagramModelVersionEntity, string>()
	@CRUD({
		name: 'Source Diagram',
		displayType: 'textarea',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sourceDiagram: string;
	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] end

	/**
	 * [CASCADE] Versions of a model
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] off begin
		name: 'Diagram Model',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.DiagramModelEntity,
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] end
	})
	public diagramModelId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public diagramModel: Models.DiagramModelEntity;

	/**
	 * [RESTRICT] Models based on this metamodel version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Version',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.MetamodelVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public metamodelVersion: Models.MetamodelVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * The hardcoded models used by the pipeline
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] off begin
		name: 'Pipeline Version',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PipelineVersionDiagramModelVersion,
		optionEqualFunc: makeJoinEqualsFunc('pipelineVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'diagramModelVersionEntity',
			oppositeEntityName: 'pipelineVersionEntity',
			relationName: 'diagramModelVersion',
			relationOppositeName: 'pipelineVersion',
			entity: () => Models.DiagramModelVersionEntity,
			joinEntity: () => Models.PipelineVersionDiagramModelVersion,
			oppositeEntity: () => Models.PipelineVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] end
	})
	public pipelineVersions: Models.PipelineVersionDiagramModelVersion[] = [];

	/**
	 * Any other models referenced by this model
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Model Version Dependency'] off begin
		name: 'Model Version Dependency',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DependentModelVersionModelVersionDependency,
		optionEqualFunc: makeJoinEqualsFunc('modelVersionDependencyId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'diagramModelVersionEntity',
			oppositeEntityName: 'diagramModelVersionEntity',
			relationName: 'dependentModelVersion',
			relationOppositeName: 'modelVersionDependency',
			entity: () => Models.DiagramModelVersionEntity,
			joinEntity: () => Models.DependentModelVersionModelVersionDependency,
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Model Version Dependency'] end
	})
	public modelVersionDependencys: Models.DependentModelVersionModelVersionDependency[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Dependent Model Version'] off begin
		name: 'Dependent Model Version',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DependentModelVersionModelVersionDependency,
		optionEqualFunc: makeJoinEqualsFunc('dependentModelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'diagramModelVersionEntity',
			oppositeEntityName: 'diagramModelVersionEntity',
			relationName: 'modelVersionDependency',
			relationOppositeName: 'dependentModelVersion',
			entity: () => Models.DiagramModelVersionEntity,
			joinEntity: () => Models.DependentModelVersionModelVersionDependency,
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Dependent Model Version'] end
	})
	public dependentModelVersions: Models.DependentModelVersionModelVersionDependency[] = [];

	/**
	 * The models used by the project
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project'] off begin
		name: 'Project',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProjectModelVersion,
		optionEqualFunc: makeJoinEqualsFunc('projectId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'diagramModelVersionEntity',
			oppositeEntityName: 'projectEntity',
			relationName: 'modelVersion',
			relationOppositeName: 'project',
			entity: () => Models.DiagramModelVersionEntity,
			joinEntity: () => Models.ProjectModelVersion,
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Project'] end
	})
	public projects: Models.ProjectModelVersion[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDiagramModelVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDiagramModelVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isWorkingVersion !== undefined) {
				this.isWorkingVersion = attributes.isWorkingVersion;
			}
			if (attributes.isPublished !== undefined) {
				this.isPublished = attributes.isPublished;
			}
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.sourceDiagram !== undefined) {
				this.sourceDiagram = attributes.sourceDiagram;
			}
			if (attributes.diagramModelId !== undefined) {
				this.diagramModelId = attributes.diagramModelId;
			}
			if (attributes.diagramModel !== undefined) {
				if (attributes.diagramModel === null) {
					this.diagramModel = attributes.diagramModel;
				} else if (attributes.diagramModel instanceof Models.DiagramModelEntity) {
					this.diagramModel = attributes.diagramModel;
					this.diagramModelId = attributes.diagramModel.id;
				} else {
					this.diagramModel = new Models.DiagramModelEntity(attributes.diagramModel);
					this.diagramModelId = this.diagramModel.id;
				}
			}
			if (attributes.metamodelVersionId !== undefined) {
				this.metamodelVersionId = attributes.metamodelVersionId;
			}
			if (attributes.metamodelVersion !== undefined) {
				if (attributes.metamodelVersion === null) {
					this.metamodelVersion = attributes.metamodelVersion;
				} else if (attributes.metamodelVersion instanceof Models.MetamodelVersionEntity) {
					this.metamodelVersion = attributes.metamodelVersion;
					this.metamodelVersionId = attributes.metamodelVersion.id;
				} else {
					this.metamodelVersion = new Models.MetamodelVersionEntity(attributes.metamodelVersion);
					this.metamodelVersionId = this.metamodelVersion.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.pipelineVersions !== undefined && Array.isArray(attributes.pipelineVersions)) {
				for (const model of attributes.pipelineVersions) {
					if (model instanceof Models.PipelineVersionDiagramModelVersion) {
						this.pipelineVersions.push(model);
					} else {
						this.pipelineVersions.push(new Models.PipelineVersionDiagramModelVersion(model));
					}
				}
			}
			if (attributes.modelVersionDependencys !== undefined && Array.isArray(attributes.modelVersionDependencys)) {
				for (const model of attributes.modelVersionDependencys) {
					if (model instanceof Models.DependentModelVersionModelVersionDependency) {
						this.modelVersionDependencys.push(model);
					} else {
						this.modelVersionDependencys.push(new Models.DependentModelVersionModelVersionDependency(model));
					}
				}
			}
			if (attributes.dependentModelVersions !== undefined && Array.isArray(attributes.dependentModelVersions)) {
				for (const model of attributes.dependentModelVersions) {
					if (model instanceof Models.DependentModelVersionModelVersionDependency) {
						this.dependentModelVersions.push(model);
					} else {
						this.dependentModelVersions.push(new Models.DependentModelVersionModelVersionDependency(model));
					}
				}
			}
			if (attributes.projects !== undefined && Array.isArray(attributes.projects)) {
				for (const model of attributes.projects) {
					if (model instanceof Models.ProjectModelVersion) {
						this.projects.push(model);
					} else {
						this.projects.push(new Models.ProjectModelVersion(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		modelVersionDependencys {
			${Models.DependentModelVersionModelVersionDependency.getAttributes().join('\n')}
			modelVersionDependency {
				${Models.DiagramModelVersionEntity.getAttributes().join('\n')}
			}
		}
		pipelineVersions {
			${Models.PipelineVersionDiagramModelVersion.getAttributes().join('\n')}
			pipelineVersion {
				${Models.PipelineVersionEntity.getAttributes().join('\n')}
			}
		}
		dependentModelVersions {
			${Models.DependentModelVersionModelVersionDependency.getAttributes().join('\n')}
			dependentModelVersion {
				${Models.DiagramModelVersionEntity.getAttributes().join('\n')}
			}
		}
		projects {
			${Models.ProjectModelVersion.getAttributes().join('\n')}
			project {
				${Models.ProjectEntity.getAttributes().join('\n')}
			}
		}
		diagramModel {
			${Models.DiagramModelEntity.getAttributes().join('\n')}
		}
		metamodelVersion {
			${Models.MetamodelVersionEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			modelVersionDependencys: {},
			pipelineVersions: {},
			dependentModelVersions: {},
			projects: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'pipelineVersions',
							'modelVersionDependencys',
							'dependentModelVersions',
							'projects',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DiagramModelVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(DiagramModelVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
