/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBotVersionEntity from 'Models/Security/Acl/VisitorsBotVersionEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBotVersionEntityAttributes extends IModelAttributes {
	version: string;
	publishedDate: Date;
	versionData: string;
	releaseNotes: string;
	inputMetadata: string;
	isPublished: boolean;

	botId?: string;
	bot?: Models.BotEntity | Models.IBotEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	projects: Array<
		| Models.ProjectBotVersion
		| Models.IProjectBotVersionAttributes
	>;
	pipelineRuns: Array<
		| Models.PipelineRunEntity
		| Models.IPipelineRunEntityAttributes
	>;
	pipelineVersions: Array<
		| Models.BotVersionPipelineVersion
		| Models.IBotVersionPipelineVersionAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BotVersionEntity', 'Bot Version')
// % protected region % [Customise your entity metadata here] end
export default class BotVersionEntity extends Model
	implements IBotVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBotVersionEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute<BotVersionEntity, string>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Published Date'] off begin
	@observable
	@attribute<BotVersionEntity, Date>()
	@CRUD({
		name: 'Published Date',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public publishedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Published Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version Data'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<BotVersionEntity, string>()
	@CRUD({
		name: 'Version Data',
		displayType: 'textarea',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public versionData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Release Notes'] off begin
	@observable
	@attribute<BotVersionEntity, string>()
	@CRUD({
		name: 'Release Notes',
		displayType: 'textarea',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public releaseNotes: string;
	// % protected region % [Modify props to the crud options here for attribute 'Release Notes'] end

	// % protected region % [Modify props to the crud options here for attribute 'Input Metadata'] off begin
	/**
	 * JSON
	 */
	@observable
	@attribute<BotVersionEntity, string>()
	@CRUD({
		name: 'Input Metadata',
		displayType: 'textarea',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public inputMetadata: string;
	// % protected region % [Modify props to the crud options here for attribute 'Input Metadata'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] off begin
	@observable
	@attribute<BotVersionEntity, boolean>()
	@CRUD({
		name: 'Is Published',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] end

	/**
	 * [CASCADE] Versions of a bot
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot'] off begin
		name: 'Bot',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.BotEntity,
		// % protected region % [Modify props to the crud options here for reference 'Bot'] end
	})
	public botId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public bot: Models.BotEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * Bot versions a project uses
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project'] off begin
		name: 'Project',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProjectBotVersion,
		optionEqualFunc: makeJoinEqualsFunc('projectId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'botVersionEntity',
			oppositeEntityName: 'projectEntity',
			relationName: 'botVersion',
			relationOppositeName: 'project',
			entity: () => Models.BotVersionEntity,
			joinEntity: () => Models.ProjectBotVersion,
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Project'] end
	})
	public projects: Models.ProjectBotVersion[] = [];

	/**
	 * [NULLIFY] Pipeline runs  executed by this bot version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] off begin
		name: 'Pipeline Runs',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.PipelineRunEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineRuns',
			oppositeEntity: () => Models.PipelineRunEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] end
	})
	public pipelineRuns: Models.PipelineRunEntity[] = [];

	/**
	 * Bot versions associated with pipeline versions
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] off begin
		name: 'Pipeline Version',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BotVersionPipelineVersion,
		optionEqualFunc: makeJoinEqualsFunc('pipelineVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'botVersionEntity',
			oppositeEntityName: 'pipelineVersionEntity',
			relationName: 'botVersion',
			relationOppositeName: 'pipelineVersion',
			entity: () => Models.BotVersionEntity,
			joinEntity: () => Models.BotVersionPipelineVersion,
			oppositeEntity: () => Models.PipelineVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] end
	})
	public pipelineVersions: Models.BotVersionPipelineVersion[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBotVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBotVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.publishedDate !== undefined) {
				if (attributes.publishedDate === null) {
					this.publishedDate = attributes.publishedDate;
				} else {
					this.publishedDate = moment(attributes.publishedDate).toDate();
				}
			}
			if (attributes.versionData !== undefined) {
				this.versionData = attributes.versionData;
			}
			if (attributes.releaseNotes !== undefined) {
				this.releaseNotes = attributes.releaseNotes;
			}
			if (attributes.inputMetadata !== undefined) {
				this.inputMetadata = attributes.inputMetadata;
			}
			if (attributes.isPublished !== undefined) {
				this.isPublished = attributes.isPublished;
			}
			if (attributes.botId !== undefined) {
				this.botId = attributes.botId;
			}
			if (attributes.bot !== undefined) {
				if (attributes.bot === null) {
					this.bot = attributes.bot;
				} else if (attributes.bot instanceof Models.BotEntity) {
					this.bot = attributes.bot;
					this.botId = attributes.bot.id;
				} else {
					this.bot = new Models.BotEntity(attributes.bot);
					this.botId = this.bot.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.projects !== undefined && Array.isArray(attributes.projects)) {
				for (const model of attributes.projects) {
					if (model instanceof Models.ProjectBotVersion) {
						this.projects.push(model);
					} else {
						this.projects.push(new Models.ProjectBotVersion(model));
					}
				}
			}
			if (attributes.pipelineRuns !== undefined && Array.isArray(attributes.pipelineRuns)) {
				for (const model of attributes.pipelineRuns) {
					if (model instanceof Models.PipelineRunEntity) {
						this.pipelineRuns.push(model);
					} else {
						this.pipelineRuns.push(new Models.PipelineRunEntity(model));
					}
				}
			}
			if (attributes.pipelineVersions !== undefined && Array.isArray(attributes.pipelineVersions)) {
				for (const model of attributes.pipelineVersions) {
					if (model instanceof Models.BotVersionPipelineVersion) {
						this.pipelineVersions.push(model);
					} else {
						this.pipelineVersions.push(new Models.BotVersionPipelineVersion(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		pipelineVersions {
			${Models.BotVersionPipelineVersion.getAttributes().join('\n')}
			pipelineVersion {
				${Models.PipelineVersionEntity.getAttributes().join('\n')}
			}
		}
		projects {
			${Models.ProjectBotVersion.getAttributes().join('\n')}
			project {
				${Models.ProjectEntity.getAttributes().join('\n')}
			}
		}
		bot {
			${Models.BotEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		pipelineRuns {
			${Models.PipelineRunEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			pipelineVersions: {},
			projects: {},
			pipelineRuns: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'projects',
							'pipelineRuns',
							'pipelineVersions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BotVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(BotVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
