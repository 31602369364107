/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { store } from 'Models/Store';
import { IAcl } from 'Models/Security/IAcl';
// % protected region % [Add any extra security service imports here] off begin
// % protected region % [Add any extra security service imports here] end

export default class SecurityService {
	// % protected region % [Add any extra security service properties here] off begin
	// % protected region % [Add any extra security service properties here] end

	public static canRead<T extends {acls?: IAcl[], new(...args: any[]): InstanceType<T>}>(modelType: T): boolean {
		if (modelType.acls && modelType.acls.some(acl => {
			if (acl.isVisitorAcl && acl.canRead()) {
				return true;
			}
			return store.userGroups.some(ug => {
				if (acl.group === ug.name) {
					return acl.canRead();
				}
				return false;
			});
		})) {
			return true;
		}
		return false;
	}

	public static canCreate<T extends {acls?: IAcl[], new(...args: any[]): InstanceType<T>}>(modelType: T): boolean {
		if (modelType.acls && modelType.acls.some(acl => {
			if (acl.isVisitorAcl && acl.canCreate()) {
				return true;
			}
			return store.userGroups.some(ug => {
				if (acl.group === ug.name) {
					return acl.canCreate();
				}
				return false;
			});
		})) {
			return true;
		}
		return false;
	}

	public static canUpdate<T extends {acls?: IAcl[], new(...args: any[]): InstanceType<T>}>(modelType: T): boolean {
		if (modelType.acls && modelType.acls.some(acl => {
			if (acl.isVisitorAcl && acl.canUpdate()) {
				return true;
			}
			return store.userGroups.some(ug => {
				if (acl.group === ug.name) {
					return acl.canUpdate();
				}
				return false;
			});
		})) {
			return true;
		}
		return false;
	}

	public static canDelete<T extends {acls?: IAcl[], new(...args: any[]): InstanceType<T>}>(modelType: T): boolean {
		if (modelType.acls && modelType.acls.some(acl => {
			if (acl.isVisitorAcl && acl.canDelete()) {
				return true;
			}
			return store.userGroups.some(ug => {
				if (acl.group === ug.name) {
					return acl.canDelete();
				}
				return false;
			});
		})) {
			return true;
		}
		return false;
	}

	// % protected region % [Add any extra security service methods here] off begin
	// % protected region % [Add any extra security service methods here] end
}
