/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMetamodelVersionEntity from 'Models/Security/Acl/VisitorsMetamodelVersionEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMetamodelVersionEntityAttributes extends IModelAttributes {
	isPublished: boolean;
	sourceDiagram: string;
	version: string;
	isWorkingVersion: boolean;
	libraryXml: string;

	diagramModelVersions: Array<
		| Models.DiagramModelVersionEntity
		| Models.IDiagramModelVersionEntityAttributes
	>;
	metamodelVersionDependencys: Array<
		| Models.DependentMetamodelVersionMetamodelVersionDependency
		| Models.IDependentMetamodelVersionMetamodelVersionDependencyAttributes
	>;
	dependentMetamodelVersions: Array<
		| Models.DependentMetamodelVersionMetamodelVersionDependency
		| Models.IDependentMetamodelVersionMetamodelVersionDependencyAttributes
	>;
	metamodelId: string;
	metamodel: Models.MetamodelEntity | Models.IMetamodelEntityAttributes;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	pipelineVersions: Array<
		| Models.PipelineVersionMetamodelVersion
		| Models.IPipelineVersionMetamodelVersionAttributes
	>;
	snippets: Array<
		| Models.SnippetEntity
		| Models.ISnippetEntityAttributes
	>;
	visualLibraryVersions: Array<
		| Models.MetamodelVersionVisualLibraryVersion
		| Models.IMetamodelVersionVisualLibraryVersionAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MetamodelVersionEntity', 'Metamodel Version')
// % protected region % [Customise your entity metadata here] end
export default class MetamodelVersionEntity extends Model
	implements IMetamodelVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMetamodelVersionEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] off begin
	@observable
	@attribute<MetamodelVersionEntity, boolean>()
	@CRUD({
		name: 'Is Published',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] on begin
	/**
	 * JSON
	 */
	@observable
	@attribute<MetamodelVersionEntity, string>()
	@CRUD({
		name: 'Source Diagram',
		displayType: 'textarea',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sourceDiagram: string;
	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute<MetamodelVersionEntity, string>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] off begin
	@observable
	@attribute<MetamodelVersionEntity, boolean>()
	@CRUD({
		name: 'Is Working Version',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isWorkingVersion: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Library Xml'] on begin
	/**
	 * XML
	 */
	@observable
	@attribute<MetamodelVersionEntity, string>()
	@CRUD({
		name: 'Library Xml',
		displayType: 'textarea',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public libraryXml: string;
	// % protected region % [Modify props to the crud options here for attribute 'Library Xml'] end

	/**
	 * [RESTRICT] Models based on this metamodel version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] off begin
		name: 'Diagram Model Versions',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.DiagramModelVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramModelVersions',
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] end
	})
	public diagramModelVersions: Models.DiagramModelVersionEntity[] = [];

	/**
	 * Any other metamodel versions this metamodel version depends on
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version Dependency'] off begin
		name: 'Metamodel Version Dependency',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DependentMetamodelVersionMetamodelVersionDependency,
		optionEqualFunc: makeJoinEqualsFunc('metamodelVersionDependencyId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'metamodelVersionEntity',
			oppositeEntityName: 'metamodelVersionEntity',
			relationName: 'dependentMetamodelVersion',
			relationOppositeName: 'metamodelVersionDependency',
			entity: () => Models.MetamodelVersionEntity,
			joinEntity: () => Models.DependentMetamodelVersionMetamodelVersionDependency,
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version Dependency'] end
	})
	public metamodelVersionDependencys: Models.DependentMetamodelVersionMetamodelVersionDependency[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Dependent Metamodel Version'] off begin
		name: 'Dependent Metamodel Version',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DependentMetamodelVersionMetamodelVersionDependency,
		optionEqualFunc: makeJoinEqualsFunc('dependentMetamodelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'metamodelVersionEntity',
			oppositeEntityName: 'metamodelVersionEntity',
			relationName: 'metamodelVersionDependency',
			relationOppositeName: 'dependentMetamodelVersion',
			entity: () => Models.MetamodelVersionEntity,
			joinEntity: () => Models.DependentMetamodelVersionMetamodelVersionDependency,
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Dependent Metamodel Version'] end
	})
	public dependentMetamodelVersions: Models.DependentMetamodelVersionMetamodelVersionDependency[] = [];

	/**
	 * [CASCADE] Versions of a metamodel
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] on begin
		name: 'Metamodel',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 70,
		referenceTypeFunc: () => Models.MetamodelEntity,
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] end
	})
	public metamodelId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public metamodel: Models.MetamodelEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] on begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 90,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * The hardcoded metamodels used by the pipeline
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] off begin
		name: 'Pipeline Version',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.PipelineVersionMetamodelVersion,
		optionEqualFunc: makeJoinEqualsFunc('pipelineVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'metamodelVersionEntity',
			oppositeEntityName: 'pipelineVersionEntity',
			relationName: 'metamodelVersion',
			relationOppositeName: 'pipelineVersion',
			entity: () => Models.MetamodelVersionEntity,
			joinEntity: () => Models.PipelineVersionMetamodelVersion,
			oppositeEntity: () => Models.PipelineVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] end
	})
	public pipelineVersions: Models.PipelineVersionMetamodelVersion[] = [];

	/**
	 * [CASCADE] Diagram node snippets compatible with this metamodel version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Snippet'] off begin
		name: 'Snippets',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.SnippetEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'snippets',
			oppositeEntity: () => Models.SnippetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Snippet'] end
	})
	public snippets: Models.SnippetEntity[] = [];

	/**
	 * Visual library versions used in a metamodel version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Visual Library Version'] off begin
		name: 'Visual Library Version',
		displayType: 'reference-multicombobox',
		order: 130,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MetamodelVersionVisualLibraryVersion,
		optionEqualFunc: makeJoinEqualsFunc('visualLibraryVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'metamodelVersionEntity',
			oppositeEntityName: 'visualLibraryVersionEntity',
			relationName: 'metamodelVersion',
			relationOppositeName: 'visualLibraryVersion',
			entity: () => Models.MetamodelVersionEntity,
			joinEntity: () => Models.MetamodelVersionVisualLibraryVersion,
			oppositeEntity: () => Models.VisualLibraryVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Visual Library Version'] end
	})
	public visualLibraryVersions: Models.MetamodelVersionVisualLibraryVersion[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMetamodelVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMetamodelVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isPublished !== undefined) {
				this.isPublished = attributes.isPublished;
			}
			if (attributes.sourceDiagram !== undefined) {
				this.sourceDiagram = attributes.sourceDiagram;
			}
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.isWorkingVersion !== undefined) {
				this.isWorkingVersion = attributes.isWorkingVersion;
			}
			if (attributes.libraryXml !== undefined) {
				this.libraryXml = attributes.libraryXml;
			}
			if (attributes.diagramModelVersions !== undefined && Array.isArray(attributes.diagramModelVersions)) {
				for (const model of attributes.diagramModelVersions) {
					if (model instanceof Models.DiagramModelVersionEntity) {
						this.diagramModelVersions.push(model);
					} else {
						this.diagramModelVersions.push(new Models.DiagramModelVersionEntity(model));
					}
				}
			}
			if (attributes.metamodelVersionDependencys !== undefined && Array.isArray(attributes.metamodelVersionDependencys)) {
				for (const model of attributes.metamodelVersionDependencys) {
					if (model instanceof Models.DependentMetamodelVersionMetamodelVersionDependency) {
						this.metamodelVersionDependencys.push(model);
					} else {
						this.metamodelVersionDependencys.push(new Models.DependentMetamodelVersionMetamodelVersionDependency(model));
					}
				}
			}
			if (attributes.dependentMetamodelVersions !== undefined && Array.isArray(attributes.dependentMetamodelVersions)) {
				for (const model of attributes.dependentMetamodelVersions) {
					if (model instanceof Models.DependentMetamodelVersionMetamodelVersionDependency) {
						this.dependentMetamodelVersions.push(model);
					} else {
						this.dependentMetamodelVersions.push(new Models.DependentMetamodelVersionMetamodelVersionDependency(model));
					}
				}
			}
			if (attributes.metamodelId !== undefined) {
				this.metamodelId = attributes.metamodelId;
			}
			if (attributes.metamodel !== undefined) {
				if (attributes.metamodel === null) {
					this.metamodel = attributes.metamodel;
				} else if (attributes.metamodel instanceof Models.MetamodelEntity) {
					this.metamodel = attributes.metamodel;
					this.metamodelId = attributes.metamodel.id;
				} else {
					this.metamodel = new Models.MetamodelEntity(attributes.metamodel);
					this.metamodelId = this.metamodel.id;
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.pipelineVersions !== undefined && Array.isArray(attributes.pipelineVersions)) {
				for (const model of attributes.pipelineVersions) {
					if (model instanceof Models.PipelineVersionMetamodelVersion) {
						this.pipelineVersions.push(model);
					} else {
						this.pipelineVersions.push(new Models.PipelineVersionMetamodelVersion(model));
					}
				}
			}
			if (attributes.snippets !== undefined && Array.isArray(attributes.snippets)) {
				for (const model of attributes.snippets) {
					if (model instanceof Models.SnippetEntity) {
						this.snippets.push(model);
					} else {
						this.snippets.push(new Models.SnippetEntity(model));
					}
				}
			}
			if (attributes.visualLibraryVersions !== undefined && Array.isArray(attributes.visualLibraryVersions)) {
				for (const model of attributes.visualLibraryVersions) {
					if (model instanceof Models.MetamodelVersionVisualLibraryVersion) {
						this.visualLibraryVersions.push(model);
					} else {
						this.visualLibraryVersions.push(new Models.MetamodelVersionVisualLibraryVersion(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		metamodelVersionDependencys {
			${Models.DependentMetamodelVersionMetamodelVersionDependency.getAttributes().join('\n')}
			metamodelVersionDependency {
				${Models.MetamodelVersionEntity.getAttributes().join('\n')}
			}
		}
		visualLibraryVersions {
			${Models.MetamodelVersionVisualLibraryVersion.getAttributes().join('\n')}
			visualLibraryVersion {
				${Models.VisualLibraryVersionEntity.getAttributes().join('\n')}
			}
		}
		dependentMetamodelVersions {
			${Models.DependentMetamodelVersionMetamodelVersionDependency.getAttributes().join('\n')}
			dependentMetamodelVersion {
				${Models.MetamodelVersionEntity.getAttributes().join('\n')}
			}
		}
		pipelineVersions {
			${Models.PipelineVersionMetamodelVersion.getAttributes().join('\n')}
			pipelineVersion {
				${Models.PipelineVersionEntity.getAttributes().join('\n')}
			}
		}
		diagramModelVersions {
			${Models.DiagramModelVersionEntity.getAttributes().join('\n')}
		}
		metamodel {
			${Models.MetamodelEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		snippets {
			${Models.SnippetEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			metamodelVersionDependencys: {},
			visualLibraryVersions: {},
			dependentMetamodelVersions: {},
			pipelineVersions: {},
			diagramModelVersions: {},
			snippets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'diagramModelVersions',
							'metamodelVersionDependencys',
							'dependentMetamodelVersions',
							'pipelineVersions',
							'snippets',
							'visualLibraryVersions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.version;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MetamodelVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(MetamodelVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
