import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface CrumbsProps {
	crumbs: ICrumb[];
}

export interface ICrumb {
	path?: string;
	name: string;
}

export default function Crumbs(props: CrumbsProps) {
	const { crumbs } = props;

	const history = useHistory();

	const navigateTo = (path?: string) => {
		if (!path) return;
		history.push(path);
	};

	return (
		<Content>
			{crumbs.map((crumb, index) => (
				<Crumb
					hasPath={crumb.path !== undefined}
					key={`${crumb.path}-${crumb.name}`}
					onClick={() => navigateTo(crumb.path)}
				>
					{crumb.name}
					{index < crumbs.length - 1 && <span>&nbsp;&gt;&nbsp;</span>}
				</Crumb>
			))}
		</Content>
	);
}

const Crumb = styled.div<{ hasPath: boolean }>`
	color: ${({ hasPath }) => hasPath ? '#0070f3' : '#000'};
	${({ hasPath }) => hasPath && `
		cursor: pointer;

		&:hover {
			color: #0055b6;
		}
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
`;
