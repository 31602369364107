/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsOrganisationEntity from 'Models/Security/Acl/VisitorsOrganisationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	name: string;

	botVersions: Array<
		| Models.BotVersionEntity
		| Models.IBotVersionEntityAttributes
	>;
	bots: Array<
		| Models.BotEntity
		| Models.IBotEntityAttributes
	>;
	diagramCheckpoints: Array<
		| Models.DiagramCheckpointEntity
		| Models.IDiagramCheckpointEntityAttributes
	>;
	diagramModelVersions: Array<
		| Models.DiagramModelVersionEntity
		| Models.IDiagramModelVersionEntityAttributes
	>;
	diagramModels: Array<
		| Models.DiagramModelEntity
		| Models.IDiagramModelEntityAttributes
	>;
	diagramNodes: Array<
		| Models.DiagramNodeEntity
		| Models.IDiagramNodeEntityAttributes
	>;
	diagrams: Array<
		| Models.DiagramEntity
		| Models.IDiagramEntityAttributes
	>;
	metamodelVersions: Array<
		| Models.MetamodelVersionEntity
		| Models.IMetamodelVersionEntityAttributes
	>;
	metamodels: Array<
		| Models.MetamodelEntity
		| Models.IMetamodelEntityAttributes
	>;
	organisationUsers: Array<
		| Models.OrganisationUserEntity
		| Models.IOrganisationUserEntityAttributes
	>;
	pipelineRuns: Array<
		| Models.PipelineRunEntity
		| Models.IPipelineRunEntityAttributes
	>;
	pipelineStepRuns: Array<
		| Models.PipelineStepRunEntity
		| Models.IPipelineStepRunEntityAttributes
	>;
	pipelineVersions: Array<
		| Models.PipelineVersionEntity
		| Models.IPipelineVersionEntityAttributes
	>;
	pipelines: Array<
		| Models.PipelineEntity
		| Models.IPipelineEntityAttributes
	>;
	projects: Array<
		| Models.ProjectEntity
		| Models.IProjectEntityAttributes
	>;
	repositoryCredentialss: Array<
		| Models.RepositoryCredentialsEntity
		| Models.IRepositoryCredentialsEntityAttributes
	>;
	repositorys: Array<
		| Models.RepositoryEntity
		| Models.IRepositoryEntityAttributes
	>;
	runners: Array<
		| Models.RunnerEntity
		| Models.IRunnerEntityAttributes
	>;
	snippets: Array<
		| Models.SnippetEntity
		| Models.ISnippetEntityAttributes
	>;
	visualLibraryVersions: Array<
		| Models.VisualLibraryVersionEntity
		| Models.IVisualLibraryVersionEntityAttributes
	>;
	visualLibrarys: Array<
		| Models.VisualLibraryEntity
		| Models.IVisualLibraryEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model
	implements IOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] off begin
		name: 'Bot Versions',
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.BotVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'botVersions',
			oppositeEntity: () => Models.BotVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bot Version'] end
	})
	public botVersions: Models.BotVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bot'] off begin
		name: 'Bots',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.BotEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'bots',
			oppositeEntity: () => Models.BotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bot'] end
	})
	public bots: Models.BotEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Checkpoint'] off begin
		name: 'Diagram Checkpoints',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.DiagramCheckpointEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramCheckpoints',
			oppositeEntity: () => Models.DiagramCheckpointEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Checkpoint'] end
	})
	public diagramCheckpoints: Models.DiagramCheckpointEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] off begin
		name: 'Diagram Model Versions',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.DiagramModelVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramModelVersions',
			oppositeEntity: () => Models.DiagramModelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model Version'] end
	})
	public diagramModelVersions: Models.DiagramModelVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] off begin
		name: 'Diagram Models',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.DiagramModelEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramModels',
			oppositeEntity: () => Models.DiagramModelEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Model'] end
	})
	public diagramModels: Models.DiagramModelEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram Node'] off begin
		name: 'Diagram Nodes',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.DiagramNodeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagramNodes',
			oppositeEntity: () => Models.DiagramNodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram Node'] end
	})
	public diagramNodes: Models.DiagramNodeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Diagram'] off begin
		name: 'Diagrams',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.DiagramEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'diagrams',
			oppositeEntity: () => Models.DiagramEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Diagram'] end
	})
	public diagrams: Models.DiagramEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Versions',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.MetamodelVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'metamodelVersions',
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersions: Models.MetamodelVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] off begin
		name: 'Metamodels',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.MetamodelEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'metamodels',
			oppositeEntity: () => Models.MetamodelEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel'] end
	})
	public metamodels: Models.MetamodelEntity[] = [];

	/**
	 * [CASCADE] Organisation users for this organisation
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation User'] off begin
		name: 'Organisation Users',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.OrganisationUserEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'organisationUsers',
			oppositeEntity: () => Models.OrganisationUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation User'] end
	})
	public organisationUsers: Models.OrganisationUserEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] off begin
		name: 'Pipeline Runs',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.PipelineRunEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineRuns',
			oppositeEntity: () => Models.PipelineRunEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Run'] end
	})
	public pipelineRuns: Models.PipelineRunEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Step Run'] off begin
		name: 'Pipeline Step Runs',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.PipelineStepRunEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineStepRuns',
			oppositeEntity: () => Models.PipelineStepRunEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Step Run'] end
	})
	public pipelineStepRuns: Models.PipelineStepRunEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] off begin
		name: 'Pipeline Versions',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.PipelineVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelineVersions',
			oppositeEntity: () => Models.PipelineVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline Version'] end
	})
	public pipelineVersions: Models.PipelineVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] off begin
		name: 'Pipelines',
		displayType: 'reference-multicombobox',
		order: 150,
		referenceTypeFunc: () => Models.PipelineEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pipelines',
			oppositeEntity: () => Models.PipelineEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pipeline'] end
	})
	public pipelines: Models.PipelineEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project'] off begin
		name: 'Projects',
		displayType: 'reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.ProjectEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'projects',
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Project'] end
	})
	public projects: Models.ProjectEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Repository Credentials'] off begin
		name: 'Repository Credentialss',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.RepositoryCredentialsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'repositoryCredentialss',
			oppositeEntity: () => Models.RepositoryCredentialsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Repository Credentials'] end
	})
	public repositoryCredentialss: Models.RepositoryCredentialsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Repository'] off begin
		name: 'Repositorys',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.RepositoryEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'repositorys',
			oppositeEntity: () => Models.RepositoryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Repository'] end
	})
	public repositorys: Models.RepositoryEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Runner'] off begin
		name: 'Runners',
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.RunnerEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'runners',
			oppositeEntity: () => Models.RunnerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Runner'] end
	})
	public runners: Models.RunnerEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Snippet'] off begin
		name: 'Snippets',
		displayType: 'reference-multicombobox',
		order: 200,
		referenceTypeFunc: () => Models.SnippetEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'snippets',
			oppositeEntity: () => Models.SnippetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Snippet'] end
	})
	public snippets: Models.SnippetEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Visual Library Version'] off begin
		name: 'Visual Library Versions',
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.VisualLibraryVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'visualLibraryVersions',
			oppositeEntity: () => Models.VisualLibraryVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Visual Library Version'] end
	})
	public visualLibraryVersions: Models.VisualLibraryVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] off begin
		name: 'Visual Librarys',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.VisualLibraryEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'visualLibrarys',
			oppositeEntity: () => Models.VisualLibraryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] end
	})
	public visualLibrarys: Models.VisualLibraryEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.botVersions !== undefined && Array.isArray(attributes.botVersions)) {
				for (const model of attributes.botVersions) {
					if (model instanceof Models.BotVersionEntity) {
						this.botVersions.push(model);
					} else {
						this.botVersions.push(new Models.BotVersionEntity(model));
					}
				}
			}
			if (attributes.bots !== undefined && Array.isArray(attributes.bots)) {
				for (const model of attributes.bots) {
					if (model instanceof Models.BotEntity) {
						this.bots.push(model);
					} else {
						this.bots.push(new Models.BotEntity(model));
					}
				}
			}
			if (attributes.diagramCheckpoints !== undefined && Array.isArray(attributes.diagramCheckpoints)) {
				for (const model of attributes.diagramCheckpoints) {
					if (model instanceof Models.DiagramCheckpointEntity) {
						this.diagramCheckpoints.push(model);
					} else {
						this.diagramCheckpoints.push(new Models.DiagramCheckpointEntity(model));
					}
				}
			}
			if (attributes.diagramModelVersions !== undefined && Array.isArray(attributes.diagramModelVersions)) {
				for (const model of attributes.diagramModelVersions) {
					if (model instanceof Models.DiagramModelVersionEntity) {
						this.diagramModelVersions.push(model);
					} else {
						this.diagramModelVersions.push(new Models.DiagramModelVersionEntity(model));
					}
				}
			}
			if (attributes.diagramModels !== undefined && Array.isArray(attributes.diagramModels)) {
				for (const model of attributes.diagramModels) {
					if (model instanceof Models.DiagramModelEntity) {
						this.diagramModels.push(model);
					} else {
						this.diagramModels.push(new Models.DiagramModelEntity(model));
					}
				}
			}
			if (attributes.diagramNodes !== undefined && Array.isArray(attributes.diagramNodes)) {
				for (const model of attributes.diagramNodes) {
					if (model instanceof Models.DiagramNodeEntity) {
						this.diagramNodes.push(model);
					} else {
						this.diagramNodes.push(new Models.DiagramNodeEntity(model));
					}
				}
			}
			if (attributes.diagrams !== undefined && Array.isArray(attributes.diagrams)) {
				for (const model of attributes.diagrams) {
					if (model instanceof Models.DiagramEntity) {
						this.diagrams.push(model);
					} else {
						this.diagrams.push(new Models.DiagramEntity(model));
					}
				}
			}
			if (attributes.metamodelVersions !== undefined && Array.isArray(attributes.metamodelVersions)) {
				for (const model of attributes.metamodelVersions) {
					if (model instanceof Models.MetamodelVersionEntity) {
						this.metamodelVersions.push(model);
					} else {
						this.metamodelVersions.push(new Models.MetamodelVersionEntity(model));
					}
				}
			}
			if (attributes.metamodels !== undefined && Array.isArray(attributes.metamodels)) {
				for (const model of attributes.metamodels) {
					if (model instanceof Models.MetamodelEntity) {
						this.metamodels.push(model);
					} else {
						this.metamodels.push(new Models.MetamodelEntity(model));
					}
				}
			}
			if (attributes.organisationUsers !== undefined && Array.isArray(attributes.organisationUsers)) {
				for (const model of attributes.organisationUsers) {
					if (model instanceof Models.OrganisationUserEntity) {
						this.organisationUsers.push(model);
					} else {
						this.organisationUsers.push(new Models.OrganisationUserEntity(model));
					}
				}
			}
			if (attributes.pipelineRuns !== undefined && Array.isArray(attributes.pipelineRuns)) {
				for (const model of attributes.pipelineRuns) {
					if (model instanceof Models.PipelineRunEntity) {
						this.pipelineRuns.push(model);
					} else {
						this.pipelineRuns.push(new Models.PipelineRunEntity(model));
					}
				}
			}
			if (attributes.pipelineStepRuns !== undefined && Array.isArray(attributes.pipelineStepRuns)) {
				for (const model of attributes.pipelineStepRuns) {
					if (model instanceof Models.PipelineStepRunEntity) {
						this.pipelineStepRuns.push(model);
					} else {
						this.pipelineStepRuns.push(new Models.PipelineStepRunEntity(model));
					}
				}
			}
			if (attributes.pipelineVersions !== undefined && Array.isArray(attributes.pipelineVersions)) {
				for (const model of attributes.pipelineVersions) {
					if (model instanceof Models.PipelineVersionEntity) {
						this.pipelineVersions.push(model);
					} else {
						this.pipelineVersions.push(new Models.PipelineVersionEntity(model));
					}
				}
			}
			if (attributes.pipelines !== undefined && Array.isArray(attributes.pipelines)) {
				for (const model of attributes.pipelines) {
					if (model instanceof Models.PipelineEntity) {
						this.pipelines.push(model);
					} else {
						this.pipelines.push(new Models.PipelineEntity(model));
					}
				}
			}
			if (attributes.projects !== undefined && Array.isArray(attributes.projects)) {
				for (const model of attributes.projects) {
					if (model instanceof Models.ProjectEntity) {
						this.projects.push(model);
					} else {
						this.projects.push(new Models.ProjectEntity(model));
					}
				}
			}
			if (attributes.repositoryCredentialss !== undefined && Array.isArray(attributes.repositoryCredentialss)) {
				for (const model of attributes.repositoryCredentialss) {
					if (model instanceof Models.RepositoryCredentialsEntity) {
						this.repositoryCredentialss.push(model);
					} else {
						this.repositoryCredentialss.push(new Models.RepositoryCredentialsEntity(model));
					}
				}
			}
			if (attributes.repositorys !== undefined && Array.isArray(attributes.repositorys)) {
				for (const model of attributes.repositorys) {
					if (model instanceof Models.RepositoryEntity) {
						this.repositorys.push(model);
					} else {
						this.repositorys.push(new Models.RepositoryEntity(model));
					}
				}
			}
			if (attributes.runners !== undefined && Array.isArray(attributes.runners)) {
				for (const model of attributes.runners) {
					if (model instanceof Models.RunnerEntity) {
						this.runners.push(model);
					} else {
						this.runners.push(new Models.RunnerEntity(model));
					}
				}
			}
			if (attributes.snippets !== undefined && Array.isArray(attributes.snippets)) {
				for (const model of attributes.snippets) {
					if (model instanceof Models.SnippetEntity) {
						this.snippets.push(model);
					} else {
						this.snippets.push(new Models.SnippetEntity(model));
					}
				}
			}
			if (attributes.visualLibraryVersions !== undefined && Array.isArray(attributes.visualLibraryVersions)) {
				for (const model of attributes.visualLibraryVersions) {
					if (model instanceof Models.VisualLibraryVersionEntity) {
						this.visualLibraryVersions.push(model);
					} else {
						this.visualLibraryVersions.push(new Models.VisualLibraryVersionEntity(model));
					}
				}
			}
			if (attributes.visualLibrarys !== undefined && Array.isArray(attributes.visualLibrarys)) {
				for (const model of attributes.visualLibrarys) {
					if (model instanceof Models.VisualLibraryEntity) {
						this.visualLibrarys.push(model);
					} else {
						this.visualLibrarys.push(new Models.VisualLibraryEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		organisationUsers {
			${Models.OrganisationUserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			botVersions: {},
			bots: {},
			diagramCheckpoints: {},
			diagramModelVersions: {},
			diagramModels: {},
			diagramNodes: {},
			diagrams: {},
			metamodelVersions: {},
			metamodels: {},
			organisationUsers: {},
			pipelineRuns: {},
			pipelineStepRuns: {},
			pipelineVersions: {},
			pipelines: {},
			projects: {},
			repositoryCredentialss: {},
			repositorys: {},
			runners: {},
			snippets: {},
			visualLibraryVersions: {},
			visualLibrarys: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'botVersions',
							'bots',
							'diagramCheckpoints',
							'diagramModelVersions',
							'diagramModels',
							'diagramNodes',
							'diagrams',
							'metamodelVersions',
							'metamodels',
							'pipelineRuns',
							'pipelineStepRuns',
							'pipelineVersions',
							'pipelines',
							'projects',
							'repositoryCredentialss',
							'repositorys',
							'runners',
							'snippets',
							'visualLibraryVersions',
							'visualLibrarys',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
