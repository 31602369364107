/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsMetamodelVersionDependencyReferenceManyToMany from '../Security/Acl/VisitorsMetamodelVersionDependencyReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDependentMetamodelVersionMetamodelVersionDependencyAttributes extends IModelAttributes {
	dependentMetamodelVersionId: string;
	metamodelVersionDependencyId: string;

	dependentMetamodelVersion: Models.MetamodelVersionEntity | Models.IMetamodelVersionEntityAttributes;
	metamodelVersionDependency: Models.MetamodelVersionEntity | Models.IMetamodelVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('DependentMetamodelVersionMetamodelVersionDependency')
export default class DependentMetamodelVersionMetamodelVersionDependency
	extends Model
	implements IDependentMetamodelVersionMetamodelVersionDependencyAttributes {
	public static acls: IAcl[] = [
		new VisitorsMetamodelVersionDependencyReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public dependentMetamodelVersionId: string;

	@observable
	@attribute()
	public metamodelVersionDependencyId: string;

	@observable
	@attribute({ isReference: true })
	public dependentMetamodelVersion: Models.MetamodelVersionEntity;

	@observable
	@attribute({ isReference: true })
	public metamodelVersionDependency: Models.MetamodelVersionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IDependentMetamodelVersionMetamodelVersionDependencyAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.dependentMetamodelVersionId) {
				this.dependentMetamodelVersionId = attributes.dependentMetamodelVersionId;
			}
			if (attributes.metamodelVersionDependencyId) {
				this.metamodelVersionDependencyId = attributes.metamodelVersionDependencyId;
			}

			if (attributes.dependentMetamodelVersion) {
				if (attributes.dependentMetamodelVersion instanceof Models.MetamodelVersionEntity) {
					this.dependentMetamodelVersion = attributes.dependentMetamodelVersion;
					this.dependentMetamodelVersionId = attributes.dependentMetamodelVersion.id;
				} else {
					this.dependentMetamodelVersion = new Models.MetamodelVersionEntity(attributes.dependentMetamodelVersion);
					this.dependentMetamodelVersionId = this.dependentMetamodelVersion.id;
				}
			} else if (attributes.dependentMetamodelVersionId !== undefined) {
				this.dependentMetamodelVersionId = attributes.dependentMetamodelVersionId;
			}

			if (attributes.metamodelVersionDependency) {
				if (attributes.metamodelVersionDependency instanceof Models.MetamodelVersionEntity) {
					this.metamodelVersionDependency = attributes.metamodelVersionDependency;
					this.metamodelVersionDependencyId = attributes.metamodelVersionDependency.id;
				} else {
					this.metamodelVersionDependency = new Models.MetamodelVersionEntity(attributes.metamodelVersionDependency);
					this.metamodelVersionDependencyId = this.metamodelVersionDependency.id;
				}
			} else if (attributes.metamodelVersionDependencyId !== undefined) {
				this.metamodelVersionDependencyId = attributes.metamodelVersionDependencyId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
