/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsModelVersionDependencyReferenceManyToMany from '../Security/Acl/VisitorsModelVersionDependencyReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDependentModelVersionModelVersionDependencyAttributes extends IModelAttributes {
	dependentModelVersionId: string;
	modelVersionDependencyId: string;

	dependentModelVersion: Models.DiagramModelVersionEntity | Models.IDiagramModelVersionEntityAttributes;
	modelVersionDependency: Models.DiagramModelVersionEntity | Models.IDiagramModelVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('DependentModelVersionModelVersionDependency')
export default class DependentModelVersionModelVersionDependency
	extends Model
	implements IDependentModelVersionModelVersionDependencyAttributes {
	public static acls: IAcl[] = [
		new VisitorsModelVersionDependencyReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public dependentModelVersionId: string;

	@observable
	@attribute()
	public modelVersionDependencyId: string;

	@observable
	@attribute({ isReference: true })
	public dependentModelVersion: Models.DiagramModelVersionEntity;

	@observable
	@attribute({ isReference: true })
	public modelVersionDependency: Models.DiagramModelVersionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IDependentModelVersionModelVersionDependencyAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.dependentModelVersionId) {
				this.dependentModelVersionId = attributes.dependentModelVersionId;
			}
			if (attributes.modelVersionDependencyId) {
				this.modelVersionDependencyId = attributes.modelVersionDependencyId;
			}

			if (attributes.dependentModelVersion) {
				if (attributes.dependentModelVersion instanceof Models.DiagramModelVersionEntity) {
					this.dependentModelVersion = attributes.dependentModelVersion;
					this.dependentModelVersionId = attributes.dependentModelVersion.id;
				} else {
					this.dependentModelVersion = new Models.DiagramModelVersionEntity(attributes.dependentModelVersion);
					this.dependentModelVersionId = this.dependentModelVersion.id;
				}
			} else if (attributes.dependentModelVersionId !== undefined) {
				this.dependentModelVersionId = attributes.dependentModelVersionId;
			}

			if (attributes.modelVersionDependency) {
				if (attributes.modelVersionDependency instanceof Models.DiagramModelVersionEntity) {
					this.modelVersionDependency = attributes.modelVersionDependency;
					this.modelVersionDependencyId = attributes.modelVersionDependency.id;
				} else {
					this.modelVersionDependency = new Models.DiagramModelVersionEntity(attributes.modelVersionDependency);
					this.modelVersionDependencyId = this.modelVersionDependency.id;
				}
			} else if (attributes.modelVersionDependencyId !== undefined) {
				this.modelVersionDependencyId = attributes.modelVersionDependencyId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
