/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsVisualLibraryVersionEntity from 'Models/Security/Acl/VisitorsVisualLibraryVersionEntity';
import VisitorsOrganisationScopedScheme from 'Models/Security/Acl/VisitorsOrganisationScopedScheme';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IVisualLibraryVersionEntityAttributes extends IModelAttributes {
	sourceDiagram: string;
	isPublished: boolean;
	version: string;
	isWorkingVersion: boolean;

	metamodelVersions: Array<
		| Models.MetamodelVersionVisualLibraryVersion
		| Models.IMetamodelVersionVisualLibraryVersionAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	visualLibraryId: string;
	visualLibrary: Models.VisualLibraryEntity | Models.IVisualLibraryEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('VisualLibraryVersionEntity', 'Visual Library Version')
// % protected region % [Customise your entity metadata here] end
export default class VisualLibraryVersionEntity extends Model
	implements IVisualLibraryVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsVisualLibraryVersionEntity(),
		new VisitorsOrganisationScopedScheme(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] on begin
	/**
	 * JSON
	 */
	@observable
	@attribute<VisualLibraryVersionEntity, string>()
	@CRUD({
		name: 'Source Diagram',
		displayType: 'textarea',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sourceDiagram: string;
	// % protected region % [Modify props to the crud options here for attribute 'Source Diagram'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] off begin
	@observable
	@attribute<VisualLibraryVersionEntity, boolean>()
	@CRUD({
		name: 'Is Published',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute<VisualLibraryVersionEntity, string>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] off begin
	@observable
	@attribute<VisualLibraryVersionEntity, boolean>()
	@CRUD({
		name: 'Is Working Version',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isWorkingVersion: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Working Version'] end

	/**
	 * Visual library versions used in a metamodel version
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] off begin
		name: 'Metamodel Version',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MetamodelVersionVisualLibraryVersion,
		optionEqualFunc: makeJoinEqualsFunc('metamodelVersionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'visualLibraryVersionEntity',
			oppositeEntityName: 'metamodelVersionEntity',
			relationName: 'visualLibraryVersion',
			relationOppositeName: 'metamodelVersion',
			entity: () => Models.VisualLibraryVersionEntity,
			joinEntity: () => Models.MetamodelVersionVisualLibraryVersion,
			oppositeEntity: () => Models.MetamodelVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metamodel Version'] end
	})
	public metamodelVersions: Models.MetamodelVersionVisualLibraryVersion[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * [CASCADE] Versions of a visual library
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] on begin
		name: 'Visual Library',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 50,
		referenceTypeFunc: () => Models.VisualLibraryEntity,
		// % protected region % [Modify props to the crud options here for reference 'Visual Library'] end
	})
	public visualLibraryId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public visualLibrary: Models.VisualLibraryEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IVisualLibraryVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IVisualLibraryVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.sourceDiagram !== undefined) {
				this.sourceDiagram = attributes.sourceDiagram;
			}
			if (attributes.isPublished !== undefined) {
				this.isPublished = attributes.isPublished;
			}
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.isWorkingVersion !== undefined) {
				this.isWorkingVersion = attributes.isWorkingVersion;
			}
			if (attributes.metamodelVersions !== undefined && Array.isArray(attributes.metamodelVersions)) {
				for (const model of attributes.metamodelVersions) {
					if (model instanceof Models.MetamodelVersionVisualLibraryVersion) {
						this.metamodelVersions.push(model);
					} else {
						this.metamodelVersions.push(new Models.MetamodelVersionVisualLibraryVersion(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.visualLibraryId !== undefined) {
				this.visualLibraryId = attributes.visualLibraryId;
			}
			if (attributes.visualLibrary !== undefined) {
				if (attributes.visualLibrary === null) {
					this.visualLibrary = attributes.visualLibrary;
				} else if (attributes.visualLibrary instanceof Models.VisualLibraryEntity) {
					this.visualLibrary = attributes.visualLibrary;
					this.visualLibraryId = attributes.visualLibrary.id;
				} else {
					this.visualLibrary = new Models.VisualLibraryEntity(attributes.visualLibrary);
					this.visualLibraryId = this.visualLibrary.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		metamodelVersions {
			${Models.MetamodelVersionVisualLibraryVersion.getAttributes().join('\n')}
			metamodelVersion {
				${Models.MetamodelVersionEntity.getAttributes().join('\n')}
			}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		visualLibrary {
			${Models.VisualLibraryEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			metamodelVersions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'metamodelVersions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.version;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(VisualLibraryVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(VisualLibraryVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
